.meditron{
    #ReceptionAddForm, #PatientAddForm, #PatientEditForm{
        .reception, .patients{
            &.add, &.edit{
                .content-scan-file-take{
                    padding-left: 10px;
                    .item-img-scan-file{
                        margin-top: 5%;
                        padding-left: 0;
                        img{
                            padding-left: 0;
                        }

                        .btn-delete-scan-file{
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translateX(-50%);
                            transform: translateY(-50%);
                            cursor: pointer;
                            display: none;
                            color: rgb(203, 219, 230);
                        }
                        &:hover{
                            .btn-delete-scan-file{
                                display: inherit;
                                &:hover{
                                    color: rgb(255, 255, 255);
                                }
                            }
                        }

                    }
                }
            }
        }
    }
}
