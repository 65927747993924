.meditron {
	.transcriber {
		&.index {
			#grid {
				.k-grid-header, .k-grid-content {
					colgroup col {
						&:nth-child(1) {
							width: 2%;
						}

						&:nth-child(2) {
							width: 3%;
						}

						&:nth-child(3) {
							width: 4%;
						}

						&:nth-child(4) {
							width: 5%;
						}

						&:nth-child(5) {
							width: 5%;
						}

						&:nth-child(6) {
							width: 3%;
						}

						&:nth-child(7) {
							width: 3%;
						}

						&:nth-child(8) {
							width: 5%;
						}

						&:nth-child(9) {
							width: 4%;
						}

						&:nth-child(10) {
							width: 6%;
						}

						&:nth-child(11) {
							width: 4%;
						}
					}
				}
			}
		}
	}
}