//---------------------- Main Forms Styles ---------------------//

#main-content {
	#service_request {
		.panel-body {
			padding: 26px;
		}
		.bootstrap-select.btn-group:not(.input-group-btn),
		.bootstrap-select.btn-group[class*="span"] {
			margin-bottom: 0;
		}
	}
	ul {
		&.bootstrap-select {
			margin-left: 0;
		}
	}
}

/*::::::::::::::::::  Reception -> Service Request  ::::::::::::::::::::::*/

.referring-add-btn{
	border-radius: 4px;
	background-color: transparent;
	color: $blue-primary;
	float: inherit;

	padding: 9px;
	padding-top: 5px;
    padding-bottom: 5px;

	border: 2px solid $blue-primary;
	margin-top: 5px;
	$radius: 4px;

	&:hover {
		@include button-state($white, $blue-primary, $blue-primary, $radius, none);
	}

	&:focus {
		outline: 0;
		@include button-state($blue-primary, $white, $blue-primary, $radius, none);
	}
}

/*::::::::::::::::::  Administration -> Configurations :::::::::::::::::::*/

#btn-daily-cron,
#btn-monthly-cron,
#btn-yearly-cron {
	margin: 5px 0;
}

#appointment_sms_notification {
	max-width: 156px;
	min-width: 56px;
}

#mammography_reminder {
	max-width: 50%;
	min-width: 56px;
}

#birad-table {
	padding-top: 50px;
	table {
		thead {
			tr {
				th {
					background-color: $blue-dark;
					&:first-child {
						visibility: hidden;
					}
				}
			}
		}
		tbody {
			td {
				text-align: center;
			}
		}
	}
}

#colorbox {
	.col-md-offset-1 {
		margin-left: 70px;
	}

	input, select, .btn-select {
		&.form-control {
			font-size: $body-font-size;
			height: 42px;
			border: solid 2px $grey-border;
			width: 100%;
			&:focus {
				border: solid 2px $blue-strong-focus;
			}
			&:hover {
				border-color: $grey-hover-border;
			}
		}

	}

	.k-widget{
		&.k-datetimepicker .k-input.form-control,
		&.k-datepicker .k-input.form-control {
			border: none;
			height: 36px;
			padding: 0;
			box-shadow: none;
		}
	}

	.help-block {
		margin: 0;
	}

	select[multiple].form-control {
		height: auto;
	}

	//Titles of forms
	legend {
		&:nth-child(1) {
			color: $blue-primary;
			padding-left: 5px;
			padding-bottom: 5px;
			font-size: 22px/$body-font-size+em;
			//border-bottom: solid 1px $blue-secondary;
			font-weight: bold;
			border: none;
		}
	}

}

.form-control {
	box-shadow: none;
}

//::::::::::::::::::::::::::::::: LABELS ::::::::::::::::::::::::::::::::::

.kendo-label {
	margin-top: 19px;

	> .k-in {
		&.label {
			padding: 0;
		}
	}
}

.container-fluid, .container {
	label, .label {
		color: $grey-label;
		font-size: 14px/$body-font-size+em;
		font-weight: normal;
		margin-top: 9px;

		&.in-footer {
			float: right;
			margin: 10px 0 10px 10px;
			line-height: 36px;
		}
	}

	label {
		&.btn {
			color: $blue-primary;
			&:hover {
				background-color: $blue-primary;
				color: $white;
			}
		}
	}
}

//::::::::::::::::::::::::::::::  PHOTOS STYLES :::::::::::::::::::::::::::::://

.patient-img, #patient-img-container {
	border: 2px solid $blue-primary;
	border-radius: 4px;
	margin: 15px auto 5px;
}

.patient-img.patient-img-reception {
	margin: 0;
}

#patient-img-container {
	width: 305px;
	height: 305px;
	margin: 0 auto;
	overflow: hidden;
	img {
		height: 301px;
		width: 301px;
	}

	.cropControls {
		top: 2px;
		right: 148px;
		border-radius: 2px;
	}

	.cropControls i.cropControlUpload {
		border-radius: 2px;
	}
}

.croppie-container {
	padding: 0;
}

.cropImgWrapper {
	top: 2px;
	border-radius: 2px;
}

#patient-canvas {
	display: none;
}

//#photo {
//    width: 0.1px;
//    height: 0.1px;
//    opacity: 0;
//    overflow: hidden;
//    position: absolute;
//    z-index: -1;
//
//    &:focus + label#label-photo {
//        background-color: $white;
//        color: $blue-primary;
//
//    }
//}
//
//#photo + label#label-photo {
//    color: $white;
//    background-color: $blue-primary;
//    display: inline-block;
//    cursor: pointer;
//    padding: 10px 15px 10px 15px;
//    border-radius: 5px;
//
//    * {
//        pointer-events: none;
//    }
//
//    &:hover {
//        background-color: $white;
//        color: $blue-primary;
//    }
//}

.k-widget{
	&.k-datepicker,
	&.k-datetimepicker{
		width: 100%;
	}
}



//----------------------- PatientsType -> Add & Edit --------------------------//

.select-picker {
	font-family: 'FontAwesome', $body-font-family;

	option {
		font-family: 'FontAwesome', $body-font-family;
	}

}

.btn-select {
	text-align: left;
	font-family: 'FontAwesome', $body-font-family;

	span {
		color: $blue-strong-focus;
	}

	&:focus {
		border-color: $blue-strong-focus;
	}
}

.dropdown-menu {
	li {
		font-family: 'FontAwesome', $body-font-family;
	}
	> {
		li {
			> {
				a {
					&:hover {
						color: $blue-primary;
						background-color: $grey-border;
					}
				}
			}
		}
	}
}

//---------------------- Reception -> Add -----------------------//

#studies {
	.panel-default > .panel-heading {
		padding: 0;
	}
}

//------Radio buttons checked------//
input[type="radio"] {
	margin-left: 25px;
	margin-top: -20px;
	z-index: 999;
	visibility: hidden;
	&:hover {
		cursor: pointer;
	}
	&:after {
		//width: 25px;
		//height: 25px;
		border-radius: 25px;
		//top: 1px;
		//left: -1px;
		position: relative;
		background-color: $white;
		color: $blue-primary;
		content: '\f10c';
		display: inline-block;
		visibility: visible;
		font-family: FontAwesome;
	}
	&:checked:after {
		//width: 25px;
		//height: 25px;
		border-radius: 25px;
		//top: 1px;
		//left: -1px;
		position: relative;
		background-color: $white;
		color: $blue-primary;
		content: '\f192';
		display: inline-block;
		visibility: visible;
		font-family: FontAwesome;
	}
}

.k-checkbox:indeterminate + .k-checkbox-label:after {
	background-color: $blue-secondary;
	left: 10px;
	top: -6px;
}

.k-checkbox-label:before {
	margin-top: -14px;
}

.remember-links {
	.k-checkbox-label:before {
		margin-top: -11px;
	}
}

/*#main-content
{

    input[type="checkbox"]
    {
        //display:none;
    }

    input[type="checkbox"] + label
    {
        display:inline-block;
        width:10px;
        height:25px;
        margin: 4px 0 0 0;
        vertical-align:middle;
        cursor:pointer;
    }

    input[type="checkbox"] + label:before
    {
        content: '';
        color: $blue-primary;
        background-color: $white;
        font-family: FontAwesome;
    }

    input[type="checkbox"]:checked + label:before
    {
        content: '\f00c';
        background-color: $blue-primary;
        color: $white;
        font-family: FontAwesome;
    }

    input[type="checkbox"]:checked + label:after {
        font-weight: bold;
    }
}*/

//-----Labels of treeView-----//

.add-edit-form {
	.k-state-selected {
		background-color: $white;
		color: $blue-primary;
		label {
			background-color: $white;
			color: $blue-primary;
		}
	}
}

//-----Container of treeView-----//
div {
	&.k-treeview {
		overflow: inherit;
	}
}

//:::::::::::::::: Inputs Textareas Selects Anchors ::::::::::::::::::://

div {
	&.bootstrap-select {
		&.changeableFilter {
			button {
				&.dropdown-toggle {
					border: 2px solid #0079C2;

					&:hover {
						border-color: #004872;
					}
				}
			}
		}
		button {
			&.dropdown-toggle {
				height: 42px;
				border: solid 2px $grey-border;
				background-color: $white;

				&:hover {
					border-color: $grey-hover-border;
				}

				&:focus {
					border: solid 2px $blue-strong-focus;
				}
			}
			&.form-control.btn-group {
				height: 42px;
			}

			span.filter-option {
				font-family: "FontAwesome", "Open Sans", sans-serif;
			}
		}

		&.form-control {
			&.in-footer {
				float: right;
				width: auto;
				margin: 10px 0 10px 10px;
				min-height: auto;
				>.btn {
					height: 37px;
				}
			}
		}
	}

	a, a:focus, a:hover, a:active {
		outline: 0;
	}

	.bootstrap-select {
		&.btn-group {
			.dropdown-toggle.filter-option {
				outline: 0;
				-webkit-box-shadow: none;
				-moz-box-shadow: none;
				-o-box-shadow: none;
				box-shadow: none;
			}
		}
		.dropdown-toggle,
		.form-control {
			&:focus {
				outline: 0;
				-webkit-box-shadow: none;
				-moz-box-shadow: none;
				-o-box-shadow: none;
				box-shadow: none;
			}
		}
	}

	input, select, textarea {
		&.form-control, &.form-control.k-input {
			font-size: $body-font-size;
			height: 42px;
			border: solid 2px $grey-border;
			width: 100%;

			&:focus {
				border: solid 2px $blue-strong-focus;
			}

			&:hover {
				border-color: $grey-hover-border;
			}

			&.alert.alert-danger {
				border: solid 2px $red-danger;
			}
		}
	}

	textarea {
		&.form-control, &.form-control.k-input {
			height: auto;
		}
	}

}

.bootstrap-select.btn-group:not(.input-group-btn), .bootstrap-select.btn-group[class*=col-] {
	min-height: 42px;
}

//:::::::::::::::::: KENDO MULTISELECT STYLES ::::::::::::::::::://

.k-popup .k-list .k-item {
	cursor: pointer;
}

.k-multiselect {
	&.form-control {
		cursor: pointer;
		font-size: $body-font-size;
		border: none;
		height: 42px;
		&:focus {
			border: solid 2px $blue-strong-focus;
		}
	}

	&.k-header {
		&.k-state-focused {
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
			border-color: $grey-border;
		}
	}

	&.k-state-border-down {
		.k-multiselect-wrap {
			border: solid 2px $blue-strong-focus;
			-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
			box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
		}
	}
}

.k-multiselect-wrap {
	font-size: $body-font-size;
	min-height: 38px;
	border: solid 2px $grey-border;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
	-webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	&:focus {
		border: solid 2px $blue-strong-focus;
	}

	li {
		margin: 1px;
		padding: 6px 24px;
		line-height: 1.5em;
		float: left;
		position: relative;
	}

	.k-select {
		top: 3px;
	}
}

textarea {
	&.form-control {
		font-size: $body-font-size;
		min-height: 84px;
		border: solid 2px $grey-border;
		&:focus {
			border: solid 2px $blue-strong-focus;
		}
	}
}

span {
	&.help-block {
		font-size: 12px/$body-font-size+em;
		color: $red-danger;
	}
}

//:::::::::::::::::: KENDO INPUTS STYLES ::::::::::::::::::://

.bootstrap-select .dropdown-toggle:focus {
	outline: 0 !important;
}

//:::::::::::::::::: KENDO DATE PICKER ::::::::::::::::::::://

.k-picker-wrap {
	&.k-state-default {
		border: solid 2px $grey-border;
		height: 36px;
		padding: 2px 30px 2px 2px;
		@include transition($transition04s);

		&:hover {
			border-color: $grey-hover-border;
		}

		&.k-state-hover {
			background-color: $white;
		}

		&.k-state-focused {
			border: solid 2px $blue-strong-focus;
			-webkit-box-shadow: none;
			box-shadow: none;
			outline: 0;
		}
	}
}

.k-widget {
	&.k-datepicker,
	&.k-datetimepicker {
		height: auto;
		border: none;

		.k-input {
			&.form-control {
				border: none;
				height: 36px;
				padding: 0;
				box-shadow: none;
			}
		}

		.k-icon, .k-sprite, .k-tool-icon {
			margin-top: 10px;
			margin-left: 1px;
		}
	}
}

//:::::::::::::::: KENDO UPLOAD INPUT STYLE ::::::::::::::::://

.k-upload {
	&.k-header {
		border: 2px solid $grey-border;
		background-color: $white;
	}

	.k-button.k-upload-button {
		color: $blue-primary;
		border: 2px solid $blue-primary;
		background-color: $white;
		font-weight: 400;
		$radius: 4px;

		&:hover {
			@include button-state($white, $blue-primary, $blue-primary, $radius, none);
		}

		&:focus {
			outline: 0;
			@include button-state($blue-primary, $white, $blue-primary, $radius, none);
		}
	}

	.k-upload-files {
		border-color: $grey-border;
		border-width: 1px 10px 0;
		padding-bottom: 0;

		.k-file {
			border: none;

			.k-file-name {
				max-width: 100%;
				white-space: normal;
			}
		}
	}
}

//:::::::::::::::::: Procedures Accordion ::::::::::::::::::://

body#app-layout {
	#steps {

		.panel-heading {
			padding: 0px 15px;
			background-color: $blue-primary;
		}

		h4 {
			&.panel-title {
				font-size: 16px/$body-font-size+em;
				font-weight: 300 !important;
				color: $white;
				padding: 10px;
			}
		}
	}

	#steps-count {
		&.badge {
			background-color: $white;
			height: 30px;
			margin: 0;
			padding-top: 4px;
			float: right;
			display: block;
			min-width: 40px;
			font-size: 18px/$body-font-size+em;
			border: 2px solid $grey-border;
			color: $grey-label;
		}
	}
}

//:::::::::::::::: USERS ::::::::::::::::::://

#signature-img-container, #logo-img-container, .signature-img, .logo-img {
	min-height: 40px;
	border: 2px solid $blue-primary;
	border-radius: 4px;
	padding: 0;
	background-color: #e3e4e8;
}

.signature-img, .logo-img {
	position: relative;
	a {
		&.btn.btn-form {
			position: absolute;
			bottom: 0;
			margin: 0;
			right: 0;
			border-radius: 0;
			color: $white;
			background-color: $blue-primary;
			opacity: 0.6;

			&:hover {
				box-shadow: none;
				opacity: 1;
				border-top-left-radius: $border-radius;
			}
		}
	}
}

#signature-img-container, #logo-img-container {
	//height: 150px;
	//width: 785px;
	margin: 0 auto;
	overflow: hidden;
	position: relative;

	// img {
	// 	height: 150px;
	// 	width: 785px;
	// }

	&.logo-dimensions {
		width: 465px;
		height: 200px;
	}

	&.report-header-dimensions {
		width: 785px;
		height: 150px;
	}

	&.report-footer-dimensions {
		width: 785px;
		height: 100px;
	}

	&.email-img-dimensions {
		width: 400px;
		height: 200px;
	}

	&.signature-dimensions {
		width: 465px;
		height: 200px;
	}
}


.inline-button {
	margin-top: 30px;
}

#removeRequestedProcedure, #removeProcedureRoom {
	display: none;
}

.k-timepicker {
	width: 100%;
}

#docent-file-content
{
	display: none;
}

//:::::::::::::::::: SEARCH ::::::::::::::::::://

#reportText{
	height: 107px;
}
.container-out{
	border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-radius: 4px;
    border-color: #e3e4e8;
}


//:::::::::::::::::: TECHNICIAN ::::::::::::::::::://

.technician{
	#TechnicianEditForm{
		.panel{
			.panel-body{
				.patient-info{
					overflow: hidden;
				}
			}
		}
	}
}


/* basic css */
input[type=checkbox]{ 
	display:none; 
}

#check-awesome{ 

	display: flex;
	width: 100%;
	color: #768082;
	font-weight: bolder;
	margin-top: 4%;
	
	label{ 

		display: inline-block;
		width: 100%;
		height: 12px;
		margin: 0 auto;
		
		span{ 

			display:block; 
			position:absolute;  
		  	-webkit-transition-duration:0.3s; 
		  	-moz-transition-duration:0.3s; 
		  	transition-duration:0.3s;
		}

	}
	.ckeckbox-left{

		margin-right: 25px;
			
		
	}
}
.box{
				
	border:1px solid #CCCCCC;
	height:14px; 
	width:14px;
	z-index:2;
	-webkit-transition-delay:0.1s; 
	-moz-transition-delay:0.1s; 
	transition-delay:0.1s;
	border-radius: 3px;


}
.check{

	width: 7px;
	height: 16px;
	border:3px solid #0079C2;  
	border-top: none;
	border-left: none;
	margin-left: 5px;
	margin-top: -2px;
	opacity:0; 
	z-index:2;
	-webkit-transform:rotate(180deg); 
	-moz-transform:rotate(180deg); 
	transform:rotate(180deg);
	-webkit-transition-delay:0.2s; 
	-moz-transition-delay:0.2s; 
	transition-delay:0.2s;	

}




/* handling click events */

input[type=checkbox]:checked ~ label {

	.box{ 

	  opacity:0;
	  -webkit-transform:scale(0) rotate(-180deg);
	  -moz-transform:scale(0) rotate(-180deg);
	  transform:scale(0) rotate(-180deg);
	}
	.check {

	  opacity:1; 
	  -webkit-transform:scale(1) rotate(45deg);
	  -moz-transform:scale(1) rotate(45deg);
	  transform:scale(1) rotate(45deg);
	}
}

/* bubble animation */

@-webkit-keyframes growCircle {
  0%, 
  100%{ 
  	-webkit-transform:scale(0); 
  }
  70%{ 
  	background:#DDD; 
  	-webkit-transform:scale(1.25); 
  }
}
@-moz-keyframes growCircle {
  0%,
  100%{ 
  	-moz-transform:scale(0); 
  }
  70%{ 
  	background:#DDD; 
  	-moz-transform:scale(1.25); 
  }
}
@keyframes growCircle {
  0%, 
  100%{ 
  	transform:scale(0); 
  }
  70%{ 
  	background:#DDD; 
  	transform:scale(1.25); 
  }
}

