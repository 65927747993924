.red-danger {
	color: $red-danger;
}

body#app-layout {
	font-family: $body-font-family;
	font-size: $body-font-size;
	font-weight: $body-font-weight;

	p {
		margin: 15px 0 0;
	}

	/*
	Use the DejaVu Sans font for display and embedding in the PDF file.
	The standard PDF fonts have no support for Unicode characters.
	*/
	.k-grid {
		font-family: "Open Sans", "Arial", sans-serif;
	}

	/* Hide the Grid header and pager during export */
	.k-pdf-export .k-grid-toolbar,
	.k-pdf-export .k-pager-wrap {
		display: none;
	}
	.k-widget.k-dialog.k-window.k-dialog-centered.k-prompt {
		padding: 0 0 0 0;
		font-size: large;
	}

	.panel {
		border: none;

		@for $i from 1 through 15 {
			&.x#{$i} {
				height: $panel-height * $i + 30px * ($i - 1);

				&.x#{$i}-less {
					height: ($panel-height * $i) - 42px + 30px * ($i - 1);

					&.elastic {
						height: auto;
						min-height: ($panel-height * $i) - 42px + 30px * ($i - 1);
					}
				}

				&.x#{$i}-less-#{$i} {
					height: ($panel-height * $i) - 21px + 30px * ($i - 1);

					&.elastic {
						height: auto;
						min-height: ($panel-height * $i) - 21px + 30px * ($i - 1);
					}
				}

				&.elastic {
					height: auto;
					min-height: $panel-height * $i + 30px * ($i - 1);
				}
			}
		}

		&.panel-input-like {
			border: 2px solid $grey-border;
			-webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
			transition: border .25s linear, color .25s linear, background-color .25s linear;
			margin-bottom: 0;

			&:hover {
				border-color: $grey-hover-border;
			}

			&:focus, &:active {
				border-color: $blue-strong-focus;
			}

			.panel-heading {
				h4.panel-title {
					color: $blue-primary;
					font-weight: 400 !important;
					font-size: $body-font-size;
				}
			}

		}


	}

	.panel-title {
		font-family: $body-font-family;
	}

	.panel-heading {
		background-color: $white;
		border: none;
		padding: 10px 15px 10px 15px;
	}

	.order-label {
		p {
			color: #000;
			margin-top: 5px;
		}
	}

	.panel-body {
		padding: 5px 15px 22px 15px;
		p {
			font-size: 14px/$body-font-size+em;
			font-weight: normal;
			line-height: $line-height-base;
			text-align: justify;

			&.text-right {
				text-align: right;
			}
		}
	}

	a:hover {
		//color:$blue-primary;
	}

	.bg-blue-m {
		background-color: $blue-primary;
	}

	.color-blue {
		color: $blue-primary;
	}

	.color-blue-strong {
		color: $blue-strong-focus;
	}

	small a {
		color: $blue-primary;

		&.text-success {
			color: $blue-strong-focus;
		}

		&:hover {
			color: $blue-strong-focus;
			text-decoration: none;
		}
	}

	.well {
		margin-left: auto;
		margin-right: auto;
	}

//::::::::::::::::Header de SHOW ::::::::::::::::::::::::
	.meditron{
		.container-fluid{
				&.show{
					.panel.sombra{
						.panel-heading{
							.info-institution{
								.rif{}
								.name{}
							}
							.logo-institution{
								h3{
									margin-bottom: 0;
								}
							}

						}
					}
				}

		}
	}

	//::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
}

.checkbox-label {
	padding: 10px 0 10px 0;
}

.sub-title {
	color: #0079c2;
	font-size: 24px/$body-font-size+em;
	margin: 0 10px 0 0;
	text-align: right;
}

.panel-title, .modal-title {
	font-size: 19px/$body-font-size+em;
	font-weight: bold !important; //I have to do this because the style.min.css has an important statement!!
	color: $blue-primary;
}

h4.panel-title {
	font-size: 18px/$body-font-size+em;
}

h5.panel-title {
	font-size: 16px/$body-font-size+em;
}

.bg-blue {
	.panel-title {
		color: #ffffff;
	}
}

.k-primary {
	color: $white;
	background-color: $blue-primary;
	border-color: $blue-primary;
	&:hover {
		color: $blue-primary;
		background-color: $white;
	}
}

.k-grid {
	tr {
		&:hover {
			background-color: $grey-row;
		}
	}
}

.ui-btn-icon-left::after {
	left: 0;
}

.glyphicon {
	font-size: 24px;
	width: 12px;
}

/*
.glyphicon-triangle-right::before {
content: "";
font-size: 14px;
top: -10px;
position: relative;
}*/

.glyphicon-triangle-right::before {
	content: "";
	float: left;
	font-size: 14px;
	left: 0;
	position: relative;
}

/*.navbar-header {
float: left;
left: -10px;
position: relative;
}*/

.alert {
	font-size: 1em;
	margin-top: 0;
	margin-bottom: 0;
	width: 100%;
}

.carousel-control .glyphicon-chevron-right {
	margin: 0 auto;
}

.arrow-white {
	color: white;
	font-size: 13px;
	left: 96%;
	margin-top: 13px;
	position: absolute;
	visibility: hidden;
}

.current .arrow-white-visible {
	color: white;
	font-size: 13px;
	left: 96%;
	margin-top: 13px;
	position: absolute;
	visibility: visible;
}

/*#principal-arrow-white{
color: white !important;
font-size: 13px;
left: 96%;
margin-top: 13px;
position: absolute !important;
visibility: visible;
}*/

.form-control:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.sidebar-large #wrapper {
  margin: 0 auto;
  min-height: $layout-min-height;
  padding-left: 0;
  position: relative;
  width: 100%;
}

#main-content {
	background-color: $color-main-content;
	margin: 0 auto;
	width: 100%;
	min-height: $layout-min-height;
	padding: 10px 0;
	position: relative;
	top: 5%;

	input:focus, select:focus, textarea:focus {
		border-color: $blue-strong-focus;
	}

	/*input[type=checkbox] {
	margin: 0;
	width: auto;
	display: none;
  }*/

  	.sombra {
		box-shadow: 3px 5px 7px rgba(184, 189, 208, 0.6);
		margin-bottom: 30px;
	}

	.container-fluid {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		/*box-shadow: 0 4px 2px -2px #c8c8c8;*/
		&:first-child{
			margin-bottom: 1.4%;
			margin-top: 3%;
		}
		&:nth-child(2){
			margin-bottom: 4%;
			margin-top: 0;
		}
		// margin-top: 4%;
		// margin-bottom: 4%;
		.title {
			background-color: $blue-primary;
			border-bottom: $border-bottom-title-grid;
			border-top-left-radius: $border-radius;
			border-top-right-radius: $border-radius;
			padding: 1em;
		}
		h1 {
			color: $white;
			font-size: 16px/$body-font-size+em;
			font-weight: bold !important; //I have to do this because style.min.css has an important statement on this property
			margin: 0;
			padding: 0;
			&.main-title {
				font-size: 22px/$body-font-size+em;
				color: $blue-primary;
				font-weight: bold;
			}
		}

		.nav-legend {
			margin: 10px 10px 5px 0;
			padding-top: 10px;
			font-weight: bold;
			color: $blue-primary;
			font-size: 22px/$body-font-size+em;
		}

		legend {
			border: 0;
			color: $blue-primary;
			font-size: 18px/$body-font-size+em;

			&.text-danger {
				a {
					color: $red-danger;

					&:hover {
						text-decoration: underline;
						color: $red-danger;
					}
				}
			}

			a {
				.fa {
					color: $blue-strong-focus;
				}
				&:hover {
					text-decoration: none;
					color: $blue-strong-focus;
				}
			}
			&.sub-legend {
				font-size: 14px/$body-font-size+em;
			}
		}
		input:focus, select:focus {
			border-color: $blue-strong-focus;
		}

		/*.radio{
		float: left;
		width: 50%;
	}*/

		.ui-radio {
			label {
				padding-left: 3%;
				cursor: inherit;
			}
		}

		#btn-add-edit-save {
			margin: 0;
			width: 120px;
		}

		/*.btn-save-add-edit {
		.add-edit-form {
		.btn-submit-form {
		margin: 0;
		}
		}
		}*/

		.title-section {
			border: 0;
			color: #2684c6;
			font-size: 1.2857em;
		}

		.search, .forms {
			background: #fff;
			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			border-radius: 5px;
			box-shadow: 0 4px 2px -2px #e8e8e8;
			margin-bottom: 1em;
			padding-bottom: 45px;
			padding-top: 45px;
			padding-left: 25px;
			.form-control input[type="text"] {
				width: 95%;
				height: 42px;
			}
		}

		.add-item {
			text-align: left;
			a {
				color: $blue-primary;
				margin-left: 10px;
				&:hover {
					color: $blue-strong-focus;
				}
			}
		}
		.search-av {
			margin-top: 13%;
			text-align: center;
		}
		#inpt_search {
			background: url("/images/iconos_menu/icons_busqueda_off.png") no-repeat scroll 5px center / 20px auto;
			padding-left: 30px;
		}

		#fls-avz-search {
			margin-top: 2%;
			margin-bottom: 2%;
		}

		.add-edit {
			position: absolute;
			width: 100%;
		}

		.add-edit-h1 {
			color: #2684c6;
			font-size: 1.2857em;
		}

		.btn-div-form {
			position: absolute;
			bottom: 50px;
		}

		.forms {
			min-height: 400px;
			/*#btn-save-add-edit{
			bottom: 5%;
			margin:0;
		}*/
		}

		.add-edit-form {
			width: 70%;
			.form-control {
				input[type="text"] {
					width: 100%;
				}
			}
			.row {
				margin-left: 30px;
				margin-right: -15px;
				margin-bottom: 15px;
			}
			.checkbox {
				p {
					margin-left: 20px;
					text-transform: uppercase;
				}
			}
			.ui-checkbox {
				label {
					padding-left: 30px;
				}
				.form-control {
					top: -5px;
				}
			}
		}
		.search {
			.ui-checkbox {
				label {
					padding-left: 30px;
				}
				.form-control {
					top: -5px;
				}
			}
		}
		.ui-radio {
			label {
				padding-left: 35px;
			}
		}
	}
}

#b_avanzada_btn {
	cursor: pointer;
}

#table-container {
	max-height: 300px;
	overflow-y: scroll;
}

.table-fixed thead {
	width: 100%;
	.col1 {
		width: 15%;
	}
	.col2 {
		width: 70%;
	}
	.col3 {
		width: 15%;
	}
}

.table-fixed tbody {
	height: 230px;
	overflow-y: auto;
	width: 100%;
	.col1 {
		width: 15%;
	}
	.col2 {
		width: 70%;
	}
	.col3 {
		width: 15%;
	}
}

.table-fixed thead, .table-fixed tbody, .table-fixed tr, .table-fixed td, .table-fixed th {
	display: block;
}

.table-fixed tbody td, .table-fixed thead > tr > th {
	float: left;
	border-bottom-width: 0;
}

.section-list li {
	background-color: transparent;
}

.arrow-down {
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.sections-container, .actions-container {
	margin-left: 25px;
	display: none;
}

#legend-add-procedure, #legend-add-created-procedure {
	display: inline;
}

#legend-delete-procedure {
	display: none;
}

a.btn-primary {
	background-color: $blue-primary;
	border-radius: 15px;
	&:hover, &:focus {
		color: $blue-primary;
		background-color: $white;
		border: solid 1px $blue-primary;
	}
}

.close {
	&.requested-procedure-close {
		position: absolute;
		right: 30px;
		top: 8px;
		color: $white;
		opacity: 1;
	}

	&.requested-procedure-close:hover {
		color: $grey-hover;
	}
}

//::::::::::::::::::::::::: Submit Buttons ::::::::::::::::::::::://

.btn:not(.dropdown-toggle), label.btn:not(.dropdown-toggle) {
	@include transition($transition04s);
}

.btn, label.btn {
	border-radius: 4px;
	background-color: transparent;
	color: $blue-primary;
	margin: 10px;
	min-width: 90px;
	$radius: 4px;

	&.btn-right {
		float: right;
		margin-right: 0;
	}

	&.btn-left {
		float: left;
		margin-left: 0;
	}

	&.btn-form {
		border: 2px solid $blue-primary;

		&:hover {
			@include button-state($white, $blue-primary, $blue-primary, $radius, none);
		}

		&:focus {
			outline: 0;
			@include button-state($blue-primary, $white, $blue-primary, $radius, none);
		}
	}

	&.btn-form-selected {
		border: 2px solid $blue-primary;
		@include button-state($white, $blue-primary, $blue-primary, $radius, none);
	}

	&.btn-danger {
		border: 2px solid $red-danger;
		color: $red-danger;

		&:hover {
			@include button-state($white, $red-danger, $red-danger, $radius, none);
		}

		&:focus {
			outline: 0;
			@include button-state($red-danger, $white, $red-danger, $radius, none);
		}
	}

	&.btn-default:not(.dropdown-toggle) {
		border: 2px solid $grey-default;
		color: $grey-default;

		&:hover {
			@include button-state($white, $grey-default, $grey-default, $radius, none);
		}

		&:focus {
			outline: 0;
			@include button-state($grey-default, $white, $grey-default, $radius, none);
		}
	}

	&.dropdown-toggle {
		margin: 0;
	}
}

//::::::::::::::::::: MODAL :::::::::::::::::::::::

.modal-footer .btn + .btn {
	margin: 10px;
}

.modal {
	z-index: 90001;

	&.modal-fancy {

		.modal-dialog {
			margin: 0 auto;
			height: 540px;
		}

		.modal-content {
			height: 540px;
		}

		.modal-body {
			height: 394px;
		}

	}

	.modal-header {
		border-color: $panel-primary-border;
		.close {
			color: $blue-primary;
			opacity: .5;
		}
	}

	.modal-footer {
		border-color: $panel-primary-border;
	}
}

.modal-content {
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}
//:::::::::::::: Scroll Action Bar ::::::::::::::::::

#scroll-action-bar {
	&.action-bar {
		background: rgba(255, 255, 255, 0.75);
		//border: 10px solid #ebebeb;
		border-radius: 5px;
		margin: 0;
		opacity: 1;
		@include transition($transition08s);
	}
}

#scroll-action-bar-hidden {
	&.action-bar-hidden {
		position: fixed;
		top: 40px;
		left: 0;
		background-color: $white;
		border-top: 2px solid $blue-primary;
		width: 100%;
		z-index: -1;
		margin: 0;
		opacity: 0;
		@include transition($transition08s);

		> .nav-legend {
			padding-top: 25px;
		}
	}
}

#scroll-action-bar, #scroll-action-bar-hidden {
	&.opacity-show {
		opacity: 1;
		z-index: 150;
	}

	&.opacity-hide {
		opacity: 0;
		z-index: -1;
	}
}

//:::::::::::::::::::::::::: MEDITRON :::::::::::::::::::::::::://
.meditron {
	&.normal-table, &#grid, &#grid1, &#grid2, &#grid3, &#grid4, &#grid5 {
		.k-grid-header, .k-grid-content {
			table {
				td {
					&.font-awesome-td {
						font-family: "FontAwesome", 'serif';
					}
				}
			}
		}
	}

	.index {
		#grid, #grid1, #grid2, #grid3, #grid4, #grid5 {

			.k-grid-header {
				table {
					th {
						&.k-header {
							vertical-align: top;
							white-space: normal;
						}
					}
				}
			}

			.k-header {
				&.k-grid-toolbar {
					float: right;
					padding-right: 10.5px;
					padding-left: 10.5px;
				}
			}
		}
	}

	.search {
		.separator {
			height: 2px;
			padding-bottom: 15px;
			border-bottom: 2px solid #e3e4e8;
		}
	}
}

.nav {
	&.nav-tabs {
		margin-top: 15px;
		border-bottom: 1px solid $grey-border;

		> li {
			> a {
				border: none;
			}
			&.active > a {
				background-color: $white;
				color: $grey-label;
				font-weight: bold;
				border-top-left-radius: 4px;
				border-top-right-radius: 4px;
			}
		}
	}
}

.nav-tabs {
	> li {
		&.active {
			a {
				border: 1px solid $grey-border;
				border-bottom-color: $white;

				&:focus, &:hover, &:active {
					border: 1px solid $grey-border;
					border-bottom-color: $white;
				}
			}
		}
	}
}

//:::::::::::::::::::: GRID ACTION BUTTONS :::::::::::::::::::://

.order-action {
	display: inline-block;
}

.actions {
	display: inline-block;
}

//:::::::::::::::::: TEMPLATES ::::::::::::::::::://

table {
	&.k-editor {
		border: solid 2px $grey-border;
		background-color: $grey-background;
	}
}

//:::::::::::::::::: CROPPIC :::::::::::::::::::://

.cropControls {
	background-color: rgba(0, 121, 194, 0.2);

	i:hover {
		background-color: rgba(0, 121, 194, 0.2);
	}
}

//::::::::::::::::: LOADER GIF ::::::::::::::::::://

#grid {
	td {
		span, div {
			&.alert {
				padding: 4px 0;
				font-size: 11px/$body-font-size+em;
				font-weight: bold;
				text-align: center;
				display: block;
				margin: 0 auto;
			}
		}
	}
}

div.loader, span.loader {
	text-align: center;
	display: none;

	&.showing {
		display: block;
	}
}

//::::::::::::::::::::: HELPERS ::::::::::::::::::::://

//Vertical align columns to center. Row must have position relative.
.vertical-center {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
}

//Pull-down columns to bottom

.pull-down {
	display: inline-block;
	float: none;
	vertical-align: bottom;
	margin: -2px;
}

.m-t-25 {
	margin-top: 25px !important;
}

.m-t-15 {
	margin-top: 15px !important;
}

.legend-address {
	padding-top: 15px;
}

/*.k-window {
	.k-edit-field {

		.k-widget.k-datetimepicker.k-header {
			display: none;
			background-color: #000;
		}

		.k-widget.k-dropdown.k-header {
			display: none;
		}

		input[type=checkbox] {
			display: none;
		}
	}

	label {

		&[for=isAllDay], &[for=start], &[for=end], &[for=recurrenceRule] {
			display: none;
		}
	}

}*/

.scheduler {
	margin-top: 20px;
}

.k-popup-edit-form  {
	.k-edit-form-container{
		width:100%;
		align-items: center;
	}
	.k-window-content{
		width: 100%;
	}
}


.flex {
	display: flex;
	flex-direction: row;
	.flex-end {
		align-self: flex-end;

		.btn {
			margin: 0;
		}
	}

	.logo-institution{
		text-align: left;
	}
}



p.institution_id {
	color: $blue-strong-focus;
	font-weight: 800;
	text-align: right;
}

h3.record-title {
	color: $blue-primary;
	font-weight: 800 !important;
}

.record-heading {
	padding-bottom: 20px;
	border-bottom: solid 1px $blue-primary;
}

.patient-img.record-img {
	margin-left: 0;
}

.img-responsive{
	border-radius: 2px;
	margin: 0 auto;
}

#record-content {
	label {
		font-weight: bold;
	}

	p {
		font-weight: 100;
	}
}

p.blue {
	color: $blue-primary;
}

.btn-icons {
	.btn-icon {
		min-width: inherit;
		padding: 6px 6px;
		margin-right: 0;
	}
}

.not-active {
	pointer-events: none;
	cursor: default;
}

.progress {
	margin-top: 15px;
}

.panel-history {
	display: none;
	&:nth-child(1) {
		display: block;
	}

	.panel-body {
		> .pane {
			margin-bottom: 10px;
			border: 2px solid $grey-border;
			border-radius: $border-radius;
		}
	}
}

audio {
	width: inherit;
	float: inherit;
}

.k-window-title {
	font-family: $body-font-family;
	font-size: 22px/$body-font-size+em;
	font-weight: bold !important; //I have to do this because the style.min.css has an important statement!!
	color: $blue-primary;
	top: 0;
}

span.k-dropdown {
	width: 100%;
	.k-dropdown-wrap {
		height: 42px;
	}
}

.col-no-padding{
	padding-right: 0px;
	padding-left: 0px;
}