.navbar {
    height: 40px;
    min-height: 40px;
    a {
        &.sidebar-toggle {
            padding: 2px;
            margin: 5px 10px;
        }
    }
    &>.container-fluid {
        .navbar-brand {
            margin: 0 0 0 15px;
        }
    }
}

.container-fluid>.navbar-collapse, .container-fluid>.navbar-header, .container>.navbar-collapse, .container>.navbar-header {
    margin: 0;
}

.navbar-inverse {

    background-color: $grey-background;
    border-bottom: 0;

    .navbar-nav > .open > a {
        background-color: transparent;

        &:focus, &:hover {
            background-color: transparent;
        }
    }

    .navbar-brand {
        background: url('/images/logo_tope.png') no-repeat center;
        background-size: 184px;
        position: relative;
        width: 184px;
        height: 40px;
        padding: 0;
    }

    .navbar-toggle {
        float: left;
        margin: 10px;
        margin-top: 2.5px;
    }
}

.navbar-header {
    float: left;
    width: 30%;
}

.navbar-collapse {
    float: right;
    width: 60%;

    .header-menu {
        font-size: 14px/$body-font-size+em;
        margin: 0;
    }

    @media (max-width: 1070px) {
        li#fecha-header, 
        li#hora-header {
            display: none;
        }
    }
}

li {

    &.config-header > a {
        padding: 5px 20px;
    }

    &#hora-header span:not(#reloj) {
        padding-left: 15px;
        border-left: 1px solid $blue-primary;
        margin-left: 10px;
    }

    &#user-header {
        padding-right: 10px;
    }

    &#hora-header {
        padding-right: 50px;
    }

    &#fecha-header {
        padding-right: 5px;
    }
}
span.username.c-text {
    line-height: 2.9em;
}

.separador {
    padding-left: 8px;
    border-left: 3px solid $blue-primary;
}

.c-text {
    color: $blue-strong-focus;
    font-weight: 600;
    line-height: 2.9em;
    padding: 3px 0 3px 8px;
}

span#arrow-docent-file
{
    &:hover
    {
        border-left: none;
    }
}