@media only screen and (max-width: 1680px) {
    @import '_general_1680';
    @import '_home_1680';
    @import '_header_1680';
    @import '_nav_1680';
    @import '_dashboard_1680';
    @import '_cms_1680';
    @import '_forms_1680';
    @import '_fancy_1680';
}

@media only screen and (max-width: 1366px) {
    @import '_general_1366';
    @import '_home_1366';
    @import '_header_1366';
    @import '_nav_1366';
    @import '_dashboard_1366';
    @import '_cms_1366';
    @import '_forms_1366';
    @import '_fancy_1366';
}

@media only screen and (max-width: 1280px) {
    @import '_general_1280';
    @import '_home_1280';
    @import '_header_1280';
    @import '_nav_1280';
    @import '_dashboard_1280';
    @import '_cms_1280';
    @import '_forms_1280';
    @import '_fancy_1280';
}