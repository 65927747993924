.input-fill{
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
input:-webkit-autofill:focus{
  @extend .input-fill
}
input:-webkit-autofill {
  @extend .input-fill
}

#main-content {
  #login-block {
    margin-top: 3%;
    padding: 0;
  }
  
  .login-box {
    -webkit-filter: drop-shadow(0px 2px 0px #e3e4e8);
    width: 350px;
    margin: 0 auto;
    top: 20px;
    label
    {
      color: $grey-text;
      font-weight: $body-font-weight;
      font-size: $body-font-size;
    }

    .login-form
    {
      input
      {
        color: $blue-primary;
        font-size: 12px/$body-font-size+em;
        margin-bottom: 5px;
      }
      label
      {
        font-size: 13px/$body-font-size+em;
      }
      a, .k-in
      {
        color: $grey-text;
        font-size: 11.5px/$body-font-size+em;
        opacity: 1;
        cursor:pointer;
        &:hover
        {
          color: $blue-primary;
        }
      }
      #submit-form{
        font-size: 14px/$body-font-size+em;
        &:hover
        {
          color:$white;
          background-color: $blue-primary;
        }
      }

    }
  }
}

.remember-links {
  margin-top: 25px;
  padding-left: 35px;
  padding-right: 35px;


  .col-md-6
  {
    padding-left:0;
    padding-right: 0;
    &:nth-child(1)
    {
      border-right: solid 2px $blue-primary;
      text-align: left;
    }
    &:nth-child(2)
    {
      text-align: center;
    }
  }
  .login-check
  {
    top: 3px;
    color: #6c6c6c;
    font-size: 0.8214285714em;
    font-weight: inherit !important;
    margin-top:0% !important;
    .check-login
    {
      font-weight: inherit;
    }
    #check-awesome
    {
      font-weight: inherit !important;
      margin-top: 0% !important;
    }
  }
}

//------Login Button-----//
.btn.btn-login
{
  display: block;
  border: 2px solid $blue-primary;
  color: $blue-primary;
  background: transparent;
  border-radius: 4px;
  &:hover
  {
    color:$white;
    background-color: $blue-primary;
  }
}
