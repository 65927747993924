.navbar {
    i.dashboard-icon {
        font-size: 16px/$body-font-size+em;
        margin: 0 5px 0 0;
        color: $blue-primary;
        &.icon-engine {
            color: $blue-strong-focus;
            font-size: 24px/$body-font-size+em;
            margin: 0;
        }
    }
}

.circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 10px;
    color: #fff;
    line-height: 20px;
    text-align: center;
    background: red;
    font-weight: bold;
    
}

#numberMessages{
    margin-top: 2px;
    margin-left: 2px;
    display: none;
}

#user-logout{
    i {
        font-size: 24px/$body-font-size+em;
        color: #0079c2;

        &:hover {
            color: #333;
        }
    }
}

.config-header {
    ul.dropdown-menu {
        border-bottom: 4px solid #0079C2;
        cursor: auto;

        li {
            cursor: auto;
            a {
                color: $blue-primary;
                &:hover {
                    background-color: transparent;
                    color: $blue-strong-focus;
                }
            }
        }

        .dropdown-footer {
            i {
                font-size: 24px/$body-font-size+em;
            }

            a {
                padding: 0 10px;
            }
        }

        .bootstrap-select {
            &:focus {
                outline: 5px solid $blue-strong-focus;
            }
            width: 100%;
            .dropdown-menu {
                width: 100%;
            }
        }

        .notifications-container{
            padding: 25px 25px 20px 25px;
            height: auto;
            max-height: 300px;
            overflow-y: auto;

            .content-message-header{  
                &:not(:first-child){
                    padding-top: 15px;
                    
                }
                border-bottom: 1px solid #DFE5E9;
                .message-text{
                    padding-bottom: 15px;
                }
            }

            .alert-content{
                width: 40px;
                height: 40px;
                margin-left: -10px;
                margin-bottom: 7px;
            }

            .normal-alert-message,
            .important-alert-message,
            .full-alert-message{
                background-size: 40px 40px;
            }

        }
    }
}

#notification-header{
    .c-text{
        line-height: 1.8em;
    }
    
}

#clave {
    border-right: 1px solid #DFE5E9;
}

.open .dropdown-toggle.btn-default {
    background-color: #ffffff;
    border: solid 2px $grey-border;
}

.header-menu
{
    .dropdown-menu
    {
        top: 42px;
        right: 10px;
        left: auto;
        max-width: 650px !important;
        width: 650px;
        min-width: 170px !important;
        margin: 0;
        padding: 0;
        border-top-left-radius: 3px !important;
        border-top-right-radius: 3px !important;

        &:after{
            border-bottom: 6px solid #0079C2;
        }
    }

    li
    {
        &:hover
        {
            cursor: auto;
        }
    }
}
/* Info template */
.k-notification-info.k-group {
    background: rgba(0%,0%,0%,.7);
    color: #fff;
}

.k-widget.k-notification.k-popup.k-group.k-reset.k-state-border-up{
    position: relative !important;

}

.approve-orders {
    width: 300px;
    height: 115px;
    h3 {
        font-size: 1em;
        padding: 32px 10px 5px;
    }
    i {
        float: left;
        margin: 30px 15px 30px 30px;
    }
}
.k-notification {
    border: 0;
}
