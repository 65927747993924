/*
** Colors
*/

$grey-text: #6c6c6c;
$grey-label:#768082;
$grey-border:#e3e4e8;
$blue-primary:#0079c2;
$blue-secondary: #37a1d5;
$blue-light: #00a2d9;
$blue-dark: #203d4f;
$blue-border-sidebar: #006596;
$grey-background: #ebebeb;
$grey-row: #f6f8fa;
$color-main-content: #f5f5f5;
$white: #ffffff;
$blue-strong-focus: #1c3e4e;
$grey-table: #989898;
$red-danger: #a94442;
$blue-hover: #078BCF;
$red-hover: #d44f4c;
$grey-default: #AFAFAF;
$grey-hover: #D0D0D0;
$grey-dark-hover: #333;
$grey-icon: #70858e;
$sidebar-grey-bg: #efefef;
$grey-hover-border: #aeaeae;

$panel-primary-border: rgba(0,121,194,0.3);

/*
** Box Shadows
*/

$box-shadow: 0 0 5px #585858;

/*
** Transitions
*/

$transition04s: all .4s cubic-bezier(0.175, 0.575, 0.275, 1.175);
$transition08s: all .8s cubic-bezier(0.175, 0.575, 0.275, 1.175);
/*
** Fonts Sizes
*/

$body-font-size: 14px;
$message-board-font-size: 16px;

/*
** Fonts Weights
*/

$body-font-weight: 300;

/*
** Fonts Family
*/
$body-font-family: 'Open Sans', sans-serif;

/*
** Layout
*/

$layout-min-height: 700px;
$layout-max-width: 1280px;
$line-height-base: 1.5em;

/*
** Borders
*/
$border-bottom-title-grid: 3px solid #1c3e4e;
$border-radius: 4px;

/*
** CARD PANELS
*/
$panel-height: 84px;
