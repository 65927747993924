#scan-file-modal{
  .modal-dialog{
    width: 64%;

    .modal-content{
      .modal-body{
        #container{
          #videoContainer{
            video#videoElement{
              height: auto;
              width: 100%;
            }
          }
          .content-canvas-to-crop{
            display: none;
          }

          #videoControls{
            text-align: center;
          }

          #canvas{
            width: 100%;
            height: auto;
          }
            .content-img-crop{
              text-align: center;
              #img_crop{
                width: 25%;
              }
            }

        }
      }
    }
  }
}
