.meditron {
	.radiologist {
		&.index {
			#grid, #grid1, #grid2, #grid3, #grid4, &#grid5 {
				.k-grid-header, .k-grid-content {
					colgroup col {
						width: 11.11%;

						&:nth-child(1) {
							//padding-left: 5px;
							width: 4%;
						}

						&:nth-child(2) {
							width: 12.9%;
						}

						&:nth-child(4) {
							width: 10%;
						}

						&:nth-child(5) {
							width: 8.8%;
						}

						&:nth-child(6) {
							width: 12%;
						}

						&:nth-child(7), &:nth-child(8) {

						}

						&:nth-child(9) {
							width: 18.33%;
						}
					}
				}
				table {
					th, td {
						&:nth-child(1) {
							padding-left: 15px;
						}
					}
					td {
						//font-family: 'Open sans', 'Helvetica', serif;
					}
				}
			}
		}

		&.edit {
			.patient-img {
				margin: 15px 0 0 0;
			}
		}
	}
}