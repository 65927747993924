.meditron {
	.technician {
		&.index {
			#grid {

				.k-header {
					&.k-grid-toolbar {
						a {
							float: right;
						}
					}
				}

				.k-grid-header, .k-grid-content {
					colgroup col {
						width: 11.11%;

						&:nth-child(1) {
							//padding-left: 5px;
							width: 3%;
						}

						&:nth-child(2) {
							width: 12.64%;
						}

						&:nth-child(4) {
							width: 9.88%;
						}

						&:nth-child(5) {
							width: 8.6%;
						}

						&:nth-child(6) {
							width: 12%;
						}

						&:nth-child(7), &:nth-child(8) {

						}

						&:nth-child(9) {
							width: 19%;
						}
					}
				}
				table {
					th, td {
						&:nth-child(1) {
							padding-left: 15px;
						}
					}
					td {
						//font-family: 'Open sans', 'Helvetica', serif;
					}
				}
			}
		}

		&.edit {
			.patient-img {
				margin: 15px 0 0 0;
			}

			.nav {
				&.nav-tabs {
					> li {
						> a {
							border: none;
						}
						&.active > a {
							background-color: $white;
							color: $grey-label;
							font-weight: bold;
							border-top-left-radius: 4px;
							border-top-right-radius: 4px;
						}
					}
				}
			}

			.nav-tabs {
				> li {
					&.active {
						a {
							border: 1px solid $grey-border;
							border-bottom-color: $white;

							&:focus, &:hover, &:active {
								border: 1px solid $grey-border;
								border-bottom-color: $white;
							}
						}
					}
				}
			}

			.nav-tabs {
				border-bottom: 1px solid $grey-border;
			}
		}

		&.printer {
			.panel {
				padding: 30px;

				.order-label {
					padding: 10px;
					border: solid #000 2px;
					border-radius: 10px;
					width: 525px;
					color: #000;

					.order-label-heading {
						&.name, &.procedure-name, &.patient-type {
							h1, h2, h3, h4, h5, h6 {
								font-weight: 600 !important;
								margin-bottom: 5px;
							}
						}
					}
				}
			}
		}

		.page-template {
			font-family: "Open Sans", "Arial", sans-serif;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
		.page-template .header {
			position: absolute;
			top: 30px;
			left: 30px;
			right: 30px;
			border-bottom: 1px solid #888;
			color: #888;
		}
		.page-template .footer {
			position: absolute;
			bottom: 30px;
			left: 30px;
			right: 30px;
			border-top: 1px solid #888;
			text-align: center;
			color: #888;
		}
		.page-template .watermark {
			font-weight: bold;
			font-size: 400%;
			text-align: center;
			margin-top: 30%;
			color: #aaaaaa;
			opacity: 0.1;
			transform: rotate(-35deg) scale(1.7, 1.5);
		}
	}
}