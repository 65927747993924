@charset "UTF-8";
/**
 * @company: Logoscorp <logoscorp.com>
 * @author: Logoscorp Dev Team <desarrollo@logoscorp.com>
 * @date: 30/05/16
 */
/*
** Colors
*/
/*
** Box Shadows
*/
/*
** Transitions
*/
/*
** Fonts Sizes
*/
/*
** Fonts Weights
*/
/*
** Fonts Family
*/
/*
** Layout
*/
/*
** Borders
*/
/*
** CARD PANELS
*/
/*:::::::::::::::: Buttons ::::::::::::::::::*/
/**
* button-state
* Used for hover, active, focus button states.
*

@params
* $bg-color: button's background color.
* $border-color: button's border color.
* $radius: button's border radius.
* $box-shadow: button's box shadow.
@endparams

**/
/*::::::::::::::: Transitions ::::::::::::::::*/
/**
* transition
* Used to avoid writing repetition of transitions.
*

@params
* $transition: transition settings.
@endparams

**/
/**
 *  Font faces to use with kendo PDF generator.
 *
**/
@font-face {
  font-family: "Open sans";
  src: url("/fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  src: url("/fonts/OpenSans-Italic.ttf") format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("/fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Open Sans";
  src: url("/fonts/OpenSans-Semibold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Open Sans";
  src: url("/fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Open Sans";
  src: url("/fonts/OpenSans-ExtraBold.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Open Sans";
  src: url("/fonts/OpenSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("/fonts/OpenSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("/fonts/OpenSans-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("/fonts/OpenSans-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: dashboard_icons;
  src: url(/fonts/mediris_icon2.woff);
  src: url(/fonts/mediris_icon2.ttf);
  font-weight: normal;
  font-style: normal;
}

.dashboard-icon {
  display: inline-block;
  font: normal normal normal 14px/1 dashboard_icons;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.icon-calendar:before {
  content: "\e900";
}

.icon-engine:before {
  content: "\e901";
}

.icon-deshabilitar:before {
  content: "\e902";
}

.icon-edit:before {
  content: "\e903";
}

.icon-file:before {
  content: "\e904";
}

.icon-lock:before {
  content: "\e905";
}

.icon-print:before {
  content: "\e906";
}

.icon-time:before {
  content: "\e907";
}

.icon-urgente:before {
  content: "\e908";
}

.icon-user:before {
  content: "\e909";
}

.icon-ver:before {
  content: "\e90a";
}

.sidebar-nav li.current a {
  border-left: 3px solid #006596;
}

.arrow {
  padding-top: 0;
  position: absolute;
  top: 45%;
}

.arrow.glyphicon {
  right: 10px;
}

.sidebar-large #sidebar {
  overflow-y: scroll;
  width: 200px;
  left: -350px;
}

.sidebar-large #sidebar .sidebar-nav {
  top: 0;
  list-style: none;
  padding: 0;
  margin-top: 54px;
}

.sidebar-large #sidebar .sidebar-nav .ico, .sidebar-large #sidebar .sidebar-nav .dashboard-icon {
  color: #70858e;
}

.sidebar-large #sidebar .sidebar-nav > li {
  position: relative;
  display: block;
  text-align: center;
  width: 190px;
}

.sidebar-large #sidebar .sidebar-nav > li:hover i {
  color: #fff;
}

.sidebar-large #sidebar .sidebar-nav > li:last-child {
  height: 88px;
}

.sidebar-large #sidebar .sidebar-nav > li:last-child:hover {
  background-color: #efefef;
}

.sidebar-large #sidebar .sidebar-nav > li a {
  position: relative;
  display: block;
  text-decoration: none;
  text-align: center;
  width: 100%;
  padding: 10px;
}

.sidebar-large #sidebar .sidebar-nav > li a.docent-file-link {
  padding-left: 25px;
}

.sidebar-large #sidebar .sidebar-nav > li a i {
  font-size: 2.8571428571em;
  text-align: center;
  display: block;
  margin: 0;
  padding: 2.5px 5px 2.5px 5px;
}

.sidebar-large #sidebar .sidebar-nav > li.current {
  background-color: #1c3e4e;
}

.sidebar-large #sidebar .sidebar-nav > li.current a:after {
  border: none;
}

.sidebar-large #sidebar .sidebar-nav > li.current i {
  color: #fff;
}

.sidebar-large #sidebar .sidebar-nav > li .sidebar-text {
  position: relative;
  display: block;
  font-size: 1em;
  padding: 5px;
}

.sidebar-large #sidebar .sidebar-nav ul.submenu li:first-child .sidebar-text {
  margin-top: 10px;
}

.sidebar-large #sidebar .sidebar-nav ul.submenu li:last-child .sidebar-text {
  margin-bottom: 10px;
}

.sidebar-large #sidebar .sidebar-nav ul.submenu li .sidebar-text {
  position: inherit;
  display: inline-block;
  height: 22px;
  padding: 5px;
  margin-left: 10px;
  background-color: transparent;
}

.sidebar-large #sidebar .sidebar-nav ul.submenu li a {
  text-align: left;
}

.sidebar-large #sidebar .sidebar-nav ul.submenu li a:hover span.sidebar-text {
  border-left: 2px solid #0079c2;
  background-color: transparent;
}

.red-danger {
  color: #a94442;
}

body#app-layout {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 300;
  /*
	Use the DejaVu Sans font for display and embedding in the PDF file.
	The standard PDF fonts have no support for Unicode characters.
	*/
  /* Hide the Grid header and pager during export */
}

body#app-layout p {
  margin: 15px 0 0;
}

body#app-layout .k-grid {
  font-family: "Open Sans", "Arial", sans-serif;
}

body#app-layout .k-pdf-export .k-grid-toolbar,
body#app-layout .k-pdf-export .k-pager-wrap {
  display: none;
}

body#app-layout .k-widget.k-dialog.k-window.k-dialog-centered.k-prompt {
  padding: 0 0 0 0;
  font-size: large;
}

body#app-layout .panel {
  border: none;
}

body#app-layout .panel.x1 {
  height: 84px;
}

body#app-layout .panel.x1.x1-less {
  height: 42px;
}

body#app-layout .panel.x1.x1-less.elastic {
  height: auto;
  min-height: 42px;
}

body#app-layout .panel.x1.x1-less-1 {
  height: 63px;
}

body#app-layout .panel.x1.x1-less-1.elastic {
  height: auto;
  min-height: 63px;
}

body#app-layout .panel.x1.elastic {
  height: auto;
  min-height: 84px;
}

body#app-layout .panel.x2 {
  height: 198px;
}

body#app-layout .panel.x2.x2-less {
  height: 156px;
}

body#app-layout .panel.x2.x2-less.elastic {
  height: auto;
  min-height: 156px;
}

body#app-layout .panel.x2.x2-less-2 {
  height: 177px;
}

body#app-layout .panel.x2.x2-less-2.elastic {
  height: auto;
  min-height: 177px;
}

body#app-layout .panel.x2.elastic {
  height: auto;
  min-height: 198px;
}

body#app-layout .panel.x3 {
  height: 312px;
}

body#app-layout .panel.x3.x3-less {
  height: 270px;
}

body#app-layout .panel.x3.x3-less.elastic {
  height: auto;
  min-height: 270px;
}

body#app-layout .panel.x3.x3-less-3 {
  height: 291px;
}

body#app-layout .panel.x3.x3-less-3.elastic {
  height: auto;
  min-height: 291px;
}

body#app-layout .panel.x3.elastic {
  height: auto;
  min-height: 312px;
}

body#app-layout .panel.x4 {
  height: 426px;
}

body#app-layout .panel.x4.x4-less {
  height: 384px;
}

body#app-layout .panel.x4.x4-less.elastic {
  height: auto;
  min-height: 384px;
}

body#app-layout .panel.x4.x4-less-4 {
  height: 405px;
}

body#app-layout .panel.x4.x4-less-4.elastic {
  height: auto;
  min-height: 405px;
}

body#app-layout .panel.x4.elastic {
  height: auto;
  min-height: 426px;
}

body#app-layout .panel.x5 {
  height: 540px;
}

body#app-layout .panel.x5.x5-less {
  height: 498px;
}

body#app-layout .panel.x5.x5-less.elastic {
  height: auto;
  min-height: 498px;
}

body#app-layout .panel.x5.x5-less-5 {
  height: 519px;
}

body#app-layout .panel.x5.x5-less-5.elastic {
  height: auto;
  min-height: 519px;
}

body#app-layout .panel.x5.elastic {
  height: auto;
  min-height: 540px;
}

body#app-layout .panel.x6 {
  height: 654px;
}

body#app-layout .panel.x6.x6-less {
  height: 612px;
}

body#app-layout .panel.x6.x6-less.elastic {
  height: auto;
  min-height: 612px;
}

body#app-layout .panel.x6.x6-less-6 {
  height: 633px;
}

body#app-layout .panel.x6.x6-less-6.elastic {
  height: auto;
  min-height: 633px;
}

body#app-layout .panel.x6.elastic {
  height: auto;
  min-height: 654px;
}

body#app-layout .panel.x7 {
  height: 768px;
}

body#app-layout .panel.x7.x7-less {
  height: 726px;
}

body#app-layout .panel.x7.x7-less.elastic {
  height: auto;
  min-height: 726px;
}

body#app-layout .panel.x7.x7-less-7 {
  height: 747px;
}

body#app-layout .panel.x7.x7-less-7.elastic {
  height: auto;
  min-height: 747px;
}

body#app-layout .panel.x7.elastic {
  height: auto;
  min-height: 768px;
}

body#app-layout .panel.x8 {
  height: 882px;
}

body#app-layout .panel.x8.x8-less {
  height: 840px;
}

body#app-layout .panel.x8.x8-less.elastic {
  height: auto;
  min-height: 840px;
}

body#app-layout .panel.x8.x8-less-8 {
  height: 861px;
}

body#app-layout .panel.x8.x8-less-8.elastic {
  height: auto;
  min-height: 861px;
}

body#app-layout .panel.x8.elastic {
  height: auto;
  min-height: 882px;
}

body#app-layout .panel.x9 {
  height: 996px;
}

body#app-layout .panel.x9.x9-less {
  height: 954px;
}

body#app-layout .panel.x9.x9-less.elastic {
  height: auto;
  min-height: 954px;
}

body#app-layout .panel.x9.x9-less-9 {
  height: 975px;
}

body#app-layout .panel.x9.x9-less-9.elastic {
  height: auto;
  min-height: 975px;
}

body#app-layout .panel.x9.elastic {
  height: auto;
  min-height: 996px;
}

body#app-layout .panel.x10 {
  height: 1110px;
}

body#app-layout .panel.x10.x10-less {
  height: 1068px;
}

body#app-layout .panel.x10.x10-less.elastic {
  height: auto;
  min-height: 1068px;
}

body#app-layout .panel.x10.x10-less-10 {
  height: 1089px;
}

body#app-layout .panel.x10.x10-less-10.elastic {
  height: auto;
  min-height: 1089px;
}

body#app-layout .panel.x10.elastic {
  height: auto;
  min-height: 1110px;
}

body#app-layout .panel.x11 {
  height: 1224px;
}

body#app-layout .panel.x11.x11-less {
  height: 1182px;
}

body#app-layout .panel.x11.x11-less.elastic {
  height: auto;
  min-height: 1182px;
}

body#app-layout .panel.x11.x11-less-11 {
  height: 1203px;
}

body#app-layout .panel.x11.x11-less-11.elastic {
  height: auto;
  min-height: 1203px;
}

body#app-layout .panel.x11.elastic {
  height: auto;
  min-height: 1224px;
}

body#app-layout .panel.x12 {
  height: 1338px;
}

body#app-layout .panel.x12.x12-less {
  height: 1296px;
}

body#app-layout .panel.x12.x12-less.elastic {
  height: auto;
  min-height: 1296px;
}

body#app-layout .panel.x12.x12-less-12 {
  height: 1317px;
}

body#app-layout .panel.x12.x12-less-12.elastic {
  height: auto;
  min-height: 1317px;
}

body#app-layout .panel.x12.elastic {
  height: auto;
  min-height: 1338px;
}

body#app-layout .panel.x13 {
  height: 1452px;
}

body#app-layout .panel.x13.x13-less {
  height: 1410px;
}

body#app-layout .panel.x13.x13-less.elastic {
  height: auto;
  min-height: 1410px;
}

body#app-layout .panel.x13.x13-less-13 {
  height: 1431px;
}

body#app-layout .panel.x13.x13-less-13.elastic {
  height: auto;
  min-height: 1431px;
}

body#app-layout .panel.x13.elastic {
  height: auto;
  min-height: 1452px;
}

body#app-layout .panel.x14 {
  height: 1566px;
}

body#app-layout .panel.x14.x14-less {
  height: 1524px;
}

body#app-layout .panel.x14.x14-less.elastic {
  height: auto;
  min-height: 1524px;
}

body#app-layout .panel.x14.x14-less-14 {
  height: 1545px;
}

body#app-layout .panel.x14.x14-less-14.elastic {
  height: auto;
  min-height: 1545px;
}

body#app-layout .panel.x14.elastic {
  height: auto;
  min-height: 1566px;
}

body#app-layout .panel.x15 {
  height: 1680px;
}

body#app-layout .panel.x15.x15-less {
  height: 1638px;
}

body#app-layout .panel.x15.x15-less.elastic {
  height: auto;
  min-height: 1638px;
}

body#app-layout .panel.x15.x15-less-15 {
  height: 1659px;
}

body#app-layout .panel.x15.x15-less-15.elastic {
  height: auto;
  min-height: 1659px;
}

body#app-layout .panel.x15.elastic {
  height: auto;
  min-height: 1680px;
}

body#app-layout .panel.panel-input-like {
  border: 2px solid #e3e4e8;
  -webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
  transition: border .25s linear, color .25s linear, background-color .25s linear;
  margin-bottom: 0;
}

body#app-layout .panel.panel-input-like:hover {
  border-color: #aeaeae;
}

body#app-layout .panel.panel-input-like:focus, body#app-layout .panel.panel-input-like:active {
  border-color: #1c3e4e;
}

body#app-layout .panel.panel-input-like .panel-heading h4.panel-title {
  color: #0079c2;
  font-weight: 400 !important;
  font-size: 14px;
}

body#app-layout .panel-title {
  font-family: "Open Sans", sans-serif;
}

body#app-layout .panel-heading {
  background-color: #ffffff;
  border: none;
  padding: 10px 15px 10px 15px;
}

body#app-layout .order-label p {
  color: #000;
  margin-top: 5px;
}

body#app-layout .panel-body {
  padding: 5px 15px 22px 15px;
}

body#app-layout .panel-body p {
  font-size: 1em;
  font-weight: normal;
  line-height: 1.5em;
  text-align: justify;
}

body#app-layout .panel-body p.text-right {
  text-align: right;
}

body#app-layout .bg-blue-m {
  background-color: #0079c2;
}

body#app-layout .color-blue {
  color: #0079c2;
}

body#app-layout .color-blue-strong {
  color: #1c3e4e;
}

body#app-layout small a {
  color: #0079c2;
}

body#app-layout small a.text-success {
  color: #1c3e4e;
}

body#app-layout small a:hover {
  color: #1c3e4e;
  text-decoration: none;
}

body#app-layout .well {
  margin-left: auto;
  margin-right: auto;
}

body#app-layout .meditron .container-fluid.show .panel.sombra .panel-heading .logo-institution h3 {
  margin-bottom: 0;
}

.checkbox-label {
  padding: 10px 0 10px 0;
}

.sub-title {
  color: #0079c2;
  font-size: 1.7142857143em;
  margin: 0 10px 0 0;
  text-align: right;
}

.panel-title, .modal-title {
  font-size: 1.3571428571em;
  font-weight: bold !important;
  color: #0079c2;
}

h4.panel-title {
  font-size: 1.2857142857em;
}

h5.panel-title {
  font-size: 1.1428571429em;
}

.bg-blue .panel-title {
  color: #ffffff;
}

.k-primary {
  color: #ffffff;
  background-color: #0079c2;
  border-color: #0079c2;
}

.k-primary:hover {
  color: #0079c2;
  background-color: #ffffff;
}

.k-grid tr:hover {
  background-color: #f6f8fa;
}

.ui-btn-icon-left::after {
  left: 0;
}

.glyphicon {
  font-size: 24px;
  width: 12px;
}

/*
.glyphicon-triangle-right::before {
content: "";
font-size: 14px;
top: -10px;
position: relative;
}*/
.glyphicon-triangle-right::before {
  content: "";
  float: left;
  font-size: 14px;
  left: 0;
  position: relative;
}

/*.navbar-header {
float: left;
left: -10px;
position: relative;
}*/
.alert {
  font-size: 1em;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
}

.carousel-control .glyphicon-chevron-right {
  margin: 0 auto;
}

.arrow-white {
  color: white;
  font-size: 13px;
  left: 96%;
  margin-top: 13px;
  position: absolute;
  visibility: hidden;
}

.current .arrow-white-visible {
  color: white;
  font-size: 13px;
  left: 96%;
  margin-top: 13px;
  position: absolute;
  visibility: visible;
}

/*#principal-arrow-white{
color: white !important;
font-size: 13px;
left: 96%;
margin-top: 13px;
position: absolute !important;
visibility: visible;
}*/
.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.sidebar-large #wrapper {
  margin: 0 auto;
  min-height: 700px;
  padding-left: 0;
  position: relative;
  width: 100%;
}

#main-content {
  background-color: #f5f5f5;
  margin: 0 auto;
  width: 100%;
  min-height: 700px;
  padding: 10px 0;
  position: relative;
  top: 5%;
  /*input[type=checkbox] {
	margin: 0;
	width: auto;
	display: none;
  }*/
}

#main-content input:focus, #main-content select:focus, #main-content textarea:focus {
  border-color: #1c3e4e;
}

#main-content .sombra {
  box-shadow: 3px 5px 7px rgba(184, 189, 208, 0.6);
  margin-bottom: 30px;
}

#main-content .container-fluid {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  /*box-shadow: 0 4px 2px -2px #c8c8c8;*/
  /*.radio{
		float: left;
		width: 50%;
	}*/
  /*.btn-save-add-edit {
		.add-edit-form {
		.btn-submit-form {
		margin: 0;
		}
		}
		}*/
}

#main-content .container-fluid:first-child {
  margin-bottom: 1.4%;
  margin-top: 3%;
}

#main-content .container-fluid:nth-child(2) {
  margin-bottom: 4%;
  margin-top: 0;
}

#main-content .container-fluid .title {
  background-color: #0079c2;
  border-bottom: 3px solid #1c3e4e;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 1em;
}

#main-content .container-fluid h1 {
  color: #ffffff;
  font-size: 1.1428571429em;
  font-weight: bold !important;
  margin: 0;
  padding: 0;
}

#main-content .container-fluid h1.main-title {
  font-size: 1.5714285714em;
  color: #0079c2;
  font-weight: bold;
}

#main-content .container-fluid .nav-legend {
  margin: 10px 10px 5px 0;
  padding-top: 10px;
  font-weight: bold;
  color: #0079c2;
  font-size: 1.5714285714em;
}

#main-content .container-fluid legend {
  border: 0;
  color: #0079c2;
  font-size: 1.2857142857em;
}

#main-content .container-fluid legend.text-danger a {
  color: #a94442;
}

#main-content .container-fluid legend.text-danger a:hover {
  text-decoration: underline;
  color: #a94442;
}

#main-content .container-fluid legend a .fa {
  color: #1c3e4e;
}

#main-content .container-fluid legend a:hover {
  text-decoration: none;
  color: #1c3e4e;
}

#main-content .container-fluid legend.sub-legend {
  font-size: 1em;
}

#main-content .container-fluid input:focus, #main-content .container-fluid select:focus {
  border-color: #1c3e4e;
}

#main-content .container-fluid .ui-radio label {
  padding-left: 3%;
  cursor: inherit;
}

#main-content .container-fluid #btn-add-edit-save {
  margin: 0;
  width: 120px;
}

#main-content .container-fluid .title-section {
  border: 0;
  color: #2684c6;
  font-size: 1.2857em;
}

#main-content .container-fluid .search, #main-content .container-fluid .forms {
  background: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  box-shadow: 0 4px 2px -2px #e8e8e8;
  margin-bottom: 1em;
  padding-bottom: 45px;
  padding-top: 45px;
  padding-left: 25px;
}

#main-content .container-fluid .search .form-control input[type="text"], #main-content .container-fluid .forms .form-control input[type="text"] {
  width: 95%;
  height: 42px;
}

#main-content .container-fluid .add-item {
  text-align: left;
}

#main-content .container-fluid .add-item a {
  color: #0079c2;
  margin-left: 10px;
}

#main-content .container-fluid .add-item a:hover {
  color: #1c3e4e;
}

#main-content .container-fluid .search-av {
  margin-top: 13%;
  text-align: center;
}

#main-content .container-fluid #inpt_search {
  background: url("/images/iconos_menu/icons_busqueda_off.png") no-repeat scroll 5px center/20px auto;
  padding-left: 30px;
}

#main-content .container-fluid #fls-avz-search {
  margin-top: 2%;
  margin-bottom: 2%;
}

#main-content .container-fluid .add-edit {
  position: absolute;
  width: 100%;
}

#main-content .container-fluid .add-edit-h1 {
  color: #2684c6;
  font-size: 1.2857em;
}

#main-content .container-fluid .btn-div-form {
  position: absolute;
  bottom: 50px;
}

#main-content .container-fluid .forms {
  min-height: 400px;
  /*#btn-save-add-edit{
			bottom: 5%;
			margin:0;
		}*/
}

#main-content .container-fluid .add-edit-form {
  width: 70%;
}

#main-content .container-fluid .add-edit-form .form-control input[type="text"] {
  width: 100%;
}

#main-content .container-fluid .add-edit-form .row {
  margin-left: 30px;
  margin-right: -15px;
  margin-bottom: 15px;
}

#main-content .container-fluid .add-edit-form .checkbox p {
  margin-left: 20px;
  text-transform: uppercase;
}

#main-content .container-fluid .add-edit-form .ui-checkbox label {
  padding-left: 30px;
}

#main-content .container-fluid .add-edit-form .ui-checkbox .form-control {
  top: -5px;
}

#main-content .container-fluid .search .ui-checkbox label {
  padding-left: 30px;
}

#main-content .container-fluid .search .ui-checkbox .form-control {
  top: -5px;
}

#main-content .container-fluid .ui-radio label {
  padding-left: 35px;
}

#b_avanzada_btn {
  cursor: pointer;
}

#table-container {
  max-height: 300px;
  overflow-y: scroll;
}

.table-fixed thead {
  width: 100%;
}

.table-fixed thead .col1 {
  width: 15%;
}

.table-fixed thead .col2 {
  width: 70%;
}

.table-fixed thead .col3 {
  width: 15%;
}

.table-fixed tbody {
  height: 230px;
  overflow-y: auto;
  width: 100%;
}

.table-fixed tbody .col1 {
  width: 15%;
}

.table-fixed tbody .col2 {
  width: 70%;
}

.table-fixed tbody .col3 {
  width: 15%;
}

.table-fixed thead, .table-fixed tbody, .table-fixed tr, .table-fixed td, .table-fixed th {
  display: block;
}

.table-fixed tbody td, .table-fixed thead > tr > th {
  float: left;
  border-bottom-width: 0;
}

.section-list li {
  background-color: transparent;
}

.arrow-down {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sections-container, .actions-container {
  margin-left: 25px;
  display: none;
}

#legend-add-procedure, #legend-add-created-procedure {
  display: inline;
}

#legend-delete-procedure {
  display: none;
}

a.btn-primary {
  background-color: #0079c2;
  border-radius: 15px;
}

a.btn-primary:hover, a.btn-primary:focus {
  color: #0079c2;
  background-color: #ffffff;
  border: solid 1px #0079c2;
}

.close.requested-procedure-close {
  position: absolute;
  right: 30px;
  top: 8px;
  color: #ffffff;
  opacity: 1;
}

.close.requested-procedure-close:hover {
  color: #D0D0D0;
}

.btn:not(.dropdown-toggle), label.btn:not(.dropdown-toggle) {
  -webkit-transition: all 0.4s cubic-bezier(0.175, 0.575, 0.275, 1.175);
  -moz-transition: all 0.4s cubic-bezier(0.175, 0.575, 0.275, 1.175);
  -ms-transition: all 0.4s cubic-bezier(0.175, 0.575, 0.275, 1.175);
  -o-transition: all 0.4s cubic-bezier(0.175, 0.575, 0.275, 1.175);
  transition: all 0.4s cubic-bezier(0.175, 0.575, 0.275, 1.175);
}

.btn, label.btn {
  border-radius: 4px;
  background-color: transparent;
  color: #0079c2;
  margin: 10px;
  min-width: 90px;
}

.btn.btn-right, label.btn.btn-right {
  float: right;
  margin-right: 0;
}

.btn.btn-left, label.btn.btn-left {
  float: left;
  margin-left: 0;
}

.btn.btn-form, label.btn.btn-form {
  border: 2px solid #0079c2;
}

.btn.btn-form:hover, label.btn.btn-form:hover {
  color: #ffffff;
  background-color: #0079c2;
  border-color: #0079c2;
  border-radius: 4px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

.btn.btn-form:focus, label.btn.btn-form:focus {
  outline: 0;
  color: #0079c2;
  background-color: #ffffff;
  border-color: #0079c2;
  border-radius: 4px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

.btn.btn-form-selected, label.btn.btn-form-selected {
  border: 2px solid #0079c2;
  color: #ffffff;
  background-color: #0079c2;
  border-color: #0079c2;
  border-radius: 4px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

.btn.btn-danger, label.btn.btn-danger {
  border: 2px solid #a94442;
  color: #a94442;
}

.btn.btn-danger:hover, label.btn.btn-danger:hover {
  color: #ffffff;
  background-color: #a94442;
  border-color: #a94442;
  border-radius: 4px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

.btn.btn-danger:focus, label.btn.btn-danger:focus {
  outline: 0;
  color: #a94442;
  background-color: #ffffff;
  border-color: #a94442;
  border-radius: 4px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

.btn.btn-default:not(.dropdown-toggle), label.btn.btn-default:not(.dropdown-toggle) {
  border: 2px solid #AFAFAF;
  color: #AFAFAF;
}

.btn.btn-default:not(.dropdown-toggle):hover, label.btn.btn-default:not(.dropdown-toggle):hover {
  color: #ffffff;
  background-color: #AFAFAF;
  border-color: #AFAFAF;
  border-radius: 4px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

.btn.btn-default:not(.dropdown-toggle):focus, label.btn.btn-default:not(.dropdown-toggle):focus {
  outline: 0;
  color: #AFAFAF;
  background-color: #ffffff;
  border-color: #AFAFAF;
  border-radius: 4px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

.btn.dropdown-toggle, label.btn.dropdown-toggle {
  margin: 0;
}

.modal-footer .btn + .btn {
  margin: 10px;
}

.modal {
  z-index: 90001;
}

.modal.modal-fancy .modal-dialog {
  margin: 0 auto;
  height: 540px;
}

.modal.modal-fancy .modal-content {
  height: 540px;
}

.modal.modal-fancy .modal-body {
  height: 394px;
}

.modal .modal-header {
  border-color: rgba(0, 121, 194, 0.3);
}

.modal .modal-header .close {
  color: #0079c2;
  opacity: .5;
}

.modal .modal-footer {
  border-color: rgba(0, 121, 194, 0.3);
}

.modal-content {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

#scroll-action-bar.action-bar {
  background: rgba(255, 255, 255, 0.75);
  border-radius: 5px;
  margin: 0;
  opacity: 1;
  -webkit-transition: all 0.8s cubic-bezier(0.175, 0.575, 0.275, 1.175);
  -moz-transition: all 0.8s cubic-bezier(0.175, 0.575, 0.275, 1.175);
  -ms-transition: all 0.8s cubic-bezier(0.175, 0.575, 0.275, 1.175);
  -o-transition: all 0.8s cubic-bezier(0.175, 0.575, 0.275, 1.175);
  transition: all 0.8s cubic-bezier(0.175, 0.575, 0.275, 1.175);
}

#scroll-action-bar-hidden.action-bar-hidden {
  position: fixed;
  top: 40px;
  left: 0;
  background-color: #ffffff;
  border-top: 2px solid #0079c2;
  width: 100%;
  z-index: -1;
  margin: 0;
  opacity: 0;
  -webkit-transition: all 0.8s cubic-bezier(0.175, 0.575, 0.275, 1.175);
  -moz-transition: all 0.8s cubic-bezier(0.175, 0.575, 0.275, 1.175);
  -ms-transition: all 0.8s cubic-bezier(0.175, 0.575, 0.275, 1.175);
  -o-transition: all 0.8s cubic-bezier(0.175, 0.575, 0.275, 1.175);
  transition: all 0.8s cubic-bezier(0.175, 0.575, 0.275, 1.175);
}

#scroll-action-bar-hidden.action-bar-hidden > .nav-legend {
  padding-top: 25px;
}

#scroll-action-bar.opacity-show, #scroll-action-bar-hidden.opacity-show {
  opacity: 1;
  z-index: 150;
}

#scroll-action-bar.opacity-hide, #scroll-action-bar-hidden.opacity-hide {
  opacity: 0;
  z-index: -1;
}

.meditron.normal-table .k-grid-header table td.font-awesome-td, .meditron.normal-table .k-grid-content table td.font-awesome-td, .meditron#grid .k-grid-header table td.font-awesome-td, .meditron#grid .k-grid-content table td.font-awesome-td, .meditron#grid1 .k-grid-header table td.font-awesome-td, .meditron#grid1 .k-grid-content table td.font-awesome-td, .meditron#grid2 .k-grid-header table td.font-awesome-td, .meditron#grid2 .k-grid-content table td.font-awesome-td, .meditron#grid3 .k-grid-header table td.font-awesome-td, .meditron#grid3 .k-grid-content table td.font-awesome-td, .meditron#grid4 .k-grid-header table td.font-awesome-td, .meditron#grid4 .k-grid-content table td.font-awesome-td, .meditron#grid5 .k-grid-header table td.font-awesome-td, .meditron#grid5 .k-grid-content table td.font-awesome-td {
  font-family: "FontAwesome", 'serif';
}

.meditron .index #grid .k-grid-header table th.k-header, .meditron .index #grid1 .k-grid-header table th.k-header, .meditron .index #grid2 .k-grid-header table th.k-header, .meditron .index #grid3 .k-grid-header table th.k-header, .meditron .index #grid4 .k-grid-header table th.k-header, .meditron .index #grid5 .k-grid-header table th.k-header {
  vertical-align: top;
  white-space: normal;
}

.meditron .index #grid .k-header.k-grid-toolbar, .meditron .index #grid1 .k-header.k-grid-toolbar, .meditron .index #grid2 .k-header.k-grid-toolbar, .meditron .index #grid3 .k-header.k-grid-toolbar, .meditron .index #grid4 .k-header.k-grid-toolbar, .meditron .index #grid5 .k-header.k-grid-toolbar {
  float: right;
  padding-right: 10.5px;
  padding-left: 10.5px;
}

.meditron .search .separator {
  height: 2px;
  padding-bottom: 15px;
  border-bottom: 2px solid #e3e4e8;
}

.nav.nav-tabs {
  margin-top: 15px;
  border-bottom: 1px solid #e3e4e8;
}

.nav.nav-tabs > li > a {
  border: none;
}

.nav.nav-tabs > li.active > a {
  background-color: #ffffff;
  color: #768082;
  font-weight: bold;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.nav-tabs > li.active a {
  border: 1px solid #e3e4e8;
  border-bottom-color: #ffffff;
}

.nav-tabs > li.active a:focus, .nav-tabs > li.active a:hover, .nav-tabs > li.active a:active {
  border: 1px solid #e3e4e8;
  border-bottom-color: #ffffff;
}

.order-action {
  display: inline-block;
}

.actions {
  display: inline-block;
}

table.k-editor {
  border: solid 2px #e3e4e8;
  background-color: #ebebeb;
}

.cropControls {
  background-color: rgba(0, 121, 194, 0.2);
}

.cropControls i:hover {
  background-color: rgba(0, 121, 194, 0.2);
}

#grid td span.alert, #grid td div.alert {
  padding: 4px 0;
  font-size: 0.7857142857em;
  font-weight: bold;
  text-align: center;
  display: block;
  margin: 0 auto;
}

div.loader, span.loader {
  text-align: center;
  display: none;
}

div.loader.showing, span.loader.showing {
  display: block;
}

.vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.pull-down {
  display: inline-block;
  float: none;
  vertical-align: bottom;
  margin: -2px;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.legend-address {
  padding-top: 15px;
}

/*.k-window {
	.k-edit-field {

		.k-widget.k-datetimepicker.k-header {
			display: none;
			background-color: #000;
		}

		.k-widget.k-dropdown.k-header {
			display: none;
		}

		input[type=checkbox] {
			display: none;
		}
	}

	label {

		&[for=isAllDay], &[for=start], &[for=end], &[for=recurrenceRule] {
			display: none;
		}
	}

}*/
.scheduler {
  margin-top: 20px;
}

.k-popup-edit-form .k-edit-form-container {
  width: 100%;
  align-items: center;
}

.k-popup-edit-form .k-window-content {
  width: 100%;
}

.flex {
  display: flex;
  flex-direction: row;
}

.flex .flex-end {
  align-self: flex-end;
}

.flex .flex-end .btn {
  margin: 0;
}

.flex .logo-institution {
  text-align: left;
}

p.institution_id {
  color: #1c3e4e;
  font-weight: 800;
  text-align: right;
}

h3.record-title {
  color: #0079c2;
  font-weight: 800 !important;
}

.record-heading {
  padding-bottom: 20px;
  border-bottom: solid 1px #0079c2;
}

.patient-img.record-img {
  margin-left: 0;
}

.img-responsive {
  border-radius: 2px;
  margin: 0 auto;
}

#record-content label {
  font-weight: bold;
}

#record-content p {
  font-weight: 100;
}

p.blue {
  color: #0079c2;
}

.btn-icons .btn-icon {
  min-width: inherit;
  padding: 6px 6px;
  margin-right: 0;
}

.not-active {
  pointer-events: none;
  cursor: default;
}

.progress {
  margin-top: 15px;
}

.panel-history {
  display: none;
}

.panel-history:nth-child(1) {
  display: block;
}

.panel-history .panel-body > .pane {
  margin-bottom: 10px;
  border: 2px solid #e3e4e8;
  border-radius: 4px;
}

audio {
  width: inherit;
  float: inherit;
}

.k-window-title {
  font-family: "Open Sans", sans-serif;
  font-size: 1.5714285714em;
  font-weight: bold !important;
  color: #0079c2;
  top: 0;
}

span.k-dropdown {
  width: 100%;
}

span.k-dropdown .k-dropdown-wrap {
  height: 42px;
}

.col-no-padding {
  padding-right: 0px;
  padding-left: 0px;
}

#patient-search-modal .modal-content, #patient-search-modal .modal-dialog {
  width: 1000px;
}

#patient-search-modal .modal-content table colgroup col:nth-child(n+4), #patient-search-modal .modal-dialog table colgroup col:nth-child(n+4) {
  width: 17%;
}

#patient-search-modal .customer-photo {
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  vertical-align: middle;
  line-height: 32px;
  box-shadow: inset 0 0 1px #999, inset 0 0 10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 0 1px #999, inset 0 0 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 0 1px #999, inset 0 0 10px rgba(0, 0, 0, 0.2);
  -o-box-shadow: inset 0 0 1px #999, inset 0 0 10px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.8s cubic-bezier(0.175, 0.575, 0.275, 1.175);
  -moz-transition: all 0.8s cubic-bezier(0.175, 0.575, 0.275, 1.175);
  -ms-transition: all 0.8s cubic-bezier(0.175, 0.575, 0.275, 1.175);
  -o-transition: all 0.8s cubic-bezier(0.175, 0.575, 0.275, 1.175);
  transition: all 0.8s cubic-bezier(0.175, 0.575, 0.275, 1.175);
}

#patient-search-modal .customer-name {
  display: inline-block;
  vertical-align: middle;
  line-height: 32px;
  padding-left: 3px;
}

#appointment-search-modal .modal-content, #appointment-search-modal .modal-dialog {
  width: 900px;
}

.meditron .reception.index #grid .k-grid-header colgroup col, .meditron .reception.index #grid .k-grid-content colgroup col, .meditron .appointments.index #grid .k-grid-header colgroup col, .meditron .appointments.index #grid .k-grid-content colgroup col {
  width: 9.09%;
}

.meditron .reception.index #grid .k-grid-header colgroup col:first-child, .meditron .reception.index #grid .k-grid-content colgroup col:first-child, .meditron .appointments.index #grid .k-grid-header colgroup col:first-child, .meditron .appointments.index #grid .k-grid-content colgroup col:first-child {
  width: 6%;
}

.meditron .reception.index #grid .k-grid-header colgroup col:nth-child(2), .meditron .reception.index #grid .k-grid-content colgroup col:nth-child(2), .meditron .appointments.index #grid .k-grid-header colgroup col:nth-child(2), .meditron .appointments.index #grid .k-grid-content colgroup col:nth-child(2) {
  width: 10%;
}

.meditron .reception.index #grid .k-grid-header colgroup col:nth-child(5), .meditron .reception.index #grid .k-grid-content colgroup col:nth-child(5), .meditron .appointments.index #grid .k-grid-header colgroup col:nth-child(5), .meditron .appointments.index #grid .k-grid-content colgroup col:nth-child(5) {
  width: 12%;
}

.meditron .reception.index #grid .k-grid-header colgroup col:nth-child(7), .meditron .reception.index #grid .k-grid-content colgroup col:nth-child(7), .meditron .appointments.index #grid .k-grid-header colgroup col:nth-child(7), .meditron .appointments.index #grid .k-grid-content colgroup col:nth-child(7) {
  width: 8%;
}

.meditron .reception.index #grid .k-grid-header colgroup col:nth-child(8), .meditron .reception.index #grid .k-grid-content colgroup col:nth-child(8), .meditron .appointments.index #grid .k-grid-header colgroup col:nth-child(8), .meditron .appointments.index #grid .k-grid-content colgroup col:nth-child(8) {
  width: 9%;
}

.meditron .reception.index #grid .k-grid-header colgroup col:nth-child(9), .meditron .reception.index #grid .k-grid-content colgroup col:nth-child(9), .meditron .appointments.index #grid .k-grid-header colgroup col:nth-child(9), .meditron .appointments.index #grid .k-grid-content colgroup col:nth-child(9) {
  width: 8%;
}

.meditron .reception.index #grid .k-grid-header colgroup col:nth-child(10), .meditron .reception.index #grid .k-grid-content colgroup col:nth-child(10), .meditron .appointments.index #grid .k-grid-header colgroup col:nth-child(10), .meditron .appointments.index #grid .k-grid-content colgroup col:nth-child(10) {
  width: 8%;
}

.meditron .reception.index #grid .k-grid-header colgroup col:last-child, .meditron .reception.index #grid .k-grid-content colgroup col:last-child, .meditron .appointments.index #grid .k-grid-header colgroup col:last-child, .meditron .appointments.index #grid .k-grid-content colgroup col:last-child {
  width: 7%;
}

.meditron .reception.index #grid .k-grid-header table td:first-child, .meditron .reception.index #grid .k-grid-header table th:first-child, .meditron .reception.index #grid .k-grid-content table td:first-child, .meditron .reception.index #grid .k-grid-content table th:first-child, .meditron .appointments.index #grid .k-grid-header table td:first-child, .meditron .appointments.index #grid .k-grid-header table th:first-child, .meditron .appointments.index #grid .k-grid-content table td:first-child, .meditron .appointments.index #grid .k-grid-content table th:first-child {
  padding-left: .6em;
}

.meditron .technician.index #grid .k-header.k-grid-toolbar a {
  float: right;
}

.meditron .technician.index #grid .k-grid-header colgroup col, .meditron .technician.index #grid .k-grid-content colgroup col {
  width: 11.11%;
}

.meditron .technician.index #grid .k-grid-header colgroup col:nth-child(1), .meditron .technician.index #grid .k-grid-content colgroup col:nth-child(1) {
  width: 3%;
}

.meditron .technician.index #grid .k-grid-header colgroup col:nth-child(2), .meditron .technician.index #grid .k-grid-content colgroup col:nth-child(2) {
  width: 12.64%;
}

.meditron .technician.index #grid .k-grid-header colgroup col:nth-child(4), .meditron .technician.index #grid .k-grid-content colgroup col:nth-child(4) {
  width: 9.88%;
}

.meditron .technician.index #grid .k-grid-header colgroup col:nth-child(5), .meditron .technician.index #grid .k-grid-content colgroup col:nth-child(5) {
  width: 8.6%;
}

.meditron .technician.index #grid .k-grid-header colgroup col:nth-child(6), .meditron .technician.index #grid .k-grid-content colgroup col:nth-child(6) {
  width: 12%;
}

.meditron .technician.index #grid .k-grid-header colgroup col:nth-child(9), .meditron .technician.index #grid .k-grid-content colgroup col:nth-child(9) {
  width: 19%;
}

.meditron .technician.index #grid table th:nth-child(1), .meditron .technician.index #grid table td:nth-child(1) {
  padding-left: 15px;
}

.meditron .technician.edit .patient-img {
  margin: 15px 0 0 0;
}

.meditron .technician.edit .nav.nav-tabs > li > a {
  border: none;
}

.meditron .technician.edit .nav.nav-tabs > li.active > a {
  background-color: #ffffff;
  color: #768082;
  font-weight: bold;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.meditron .technician.edit .nav-tabs > li.active a {
  border: 1px solid #e3e4e8;
  border-bottom-color: #ffffff;
}

.meditron .technician.edit .nav-tabs > li.active a:focus, .meditron .technician.edit .nav-tabs > li.active a:hover, .meditron .technician.edit .nav-tabs > li.active a:active {
  border: 1px solid #e3e4e8;
  border-bottom-color: #ffffff;
}

.meditron .technician.edit .nav-tabs {
  border-bottom: 1px solid #e3e4e8;
}

.meditron .technician.printer .panel {
  padding: 30px;
}

.meditron .technician.printer .panel .order-label {
  padding: 10px;
  border: solid #000 2px;
  border-radius: 10px;
  width: 525px;
  color: #000;
}

.meditron .technician.printer .panel .order-label .order-label-heading.name h1, .meditron .technician.printer .panel .order-label .order-label-heading.name h2, .meditron .technician.printer .panel .order-label .order-label-heading.name h3, .meditron .technician.printer .panel .order-label .order-label-heading.name h4, .meditron .technician.printer .panel .order-label .order-label-heading.name h5, .meditron .technician.printer .panel .order-label .order-label-heading.name h6, .meditron .technician.printer .panel .order-label .order-label-heading.procedure-name h1, .meditron .technician.printer .panel .order-label .order-label-heading.procedure-name h2, .meditron .technician.printer .panel .order-label .order-label-heading.procedure-name h3, .meditron .technician.printer .panel .order-label .order-label-heading.procedure-name h4, .meditron .technician.printer .panel .order-label .order-label-heading.procedure-name h5, .meditron .technician.printer .panel .order-label .order-label-heading.procedure-name h6, .meditron .technician.printer .panel .order-label .order-label-heading.patient-type h1, .meditron .technician.printer .panel .order-label .order-label-heading.patient-type h2, .meditron .technician.printer .panel .order-label .order-label-heading.patient-type h3, .meditron .technician.printer .panel .order-label .order-label-heading.patient-type h4, .meditron .technician.printer .panel .order-label .order-label-heading.patient-type h5, .meditron .technician.printer .panel .order-label .order-label-heading.patient-type h6 {
  font-weight: 600 !important;
  margin-bottom: 5px;
}

.meditron .technician .page-template {
  font-family: "Open Sans", "Arial", sans-serif;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.meditron .technician .page-template .header {
  position: absolute;
  top: 30px;
  left: 30px;
  right: 30px;
  border-bottom: 1px solid #888;
  color: #888;
}

.meditron .technician .page-template .footer {
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
  border-top: 1px solid #888;
  text-align: center;
  color: #888;
}

.meditron .technician .page-template .watermark {
  font-weight: bold;
  font-size: 400%;
  text-align: center;
  margin-top: 30%;
  color: #aaaaaa;
  opacity: 0.1;
  transform: rotate(-35deg) scale(1.7, 1.5);
}

.meditron .radiologist.index #grid .k-grid-header colgroup col, .meditron .radiologist.index #grid .k-grid-content colgroup col, .meditron .radiologist.index #grid1 .k-grid-header colgroup col, .meditron .radiologist.index #grid1 .k-grid-content colgroup col, .meditron .radiologist.index #grid2 .k-grid-header colgroup col, .meditron .radiologist.index #grid2 .k-grid-content colgroup col, .meditron .radiologist.index #grid3 .k-grid-header colgroup col, .meditron .radiologist.index #grid3 .k-grid-content colgroup col, .meditron .radiologist.index #grid4 .k-grid-header colgroup col, .meditron .radiologist.index #grid4 .k-grid-content colgroup col, .meditron .radiologist.index#grid5 .k-grid-header colgroup col, .meditron .radiologist.index#grid5 .k-grid-content colgroup col {
  width: 11.11%;
}

.meditron .radiologist.index #grid .k-grid-header colgroup col:nth-child(1), .meditron .radiologist.index #grid .k-grid-content colgroup col:nth-child(1), .meditron .radiologist.index #grid1 .k-grid-header colgroup col:nth-child(1), .meditron .radiologist.index #grid1 .k-grid-content colgroup col:nth-child(1), .meditron .radiologist.index #grid2 .k-grid-header colgroup col:nth-child(1), .meditron .radiologist.index #grid2 .k-grid-content colgroup col:nth-child(1), .meditron .radiologist.index #grid3 .k-grid-header colgroup col:nth-child(1), .meditron .radiologist.index #grid3 .k-grid-content colgroup col:nth-child(1), .meditron .radiologist.index #grid4 .k-grid-header colgroup col:nth-child(1), .meditron .radiologist.index #grid4 .k-grid-content colgroup col:nth-child(1), .meditron .radiologist.index#grid5 .k-grid-header colgroup col:nth-child(1), .meditron .radiologist.index#grid5 .k-grid-content colgroup col:nth-child(1) {
  width: 4%;
}

.meditron .radiologist.index #grid .k-grid-header colgroup col:nth-child(2), .meditron .radiologist.index #grid .k-grid-content colgroup col:nth-child(2), .meditron .radiologist.index #grid1 .k-grid-header colgroup col:nth-child(2), .meditron .radiologist.index #grid1 .k-grid-content colgroup col:nth-child(2), .meditron .radiologist.index #grid2 .k-grid-header colgroup col:nth-child(2), .meditron .radiologist.index #grid2 .k-grid-content colgroup col:nth-child(2), .meditron .radiologist.index #grid3 .k-grid-header colgroup col:nth-child(2), .meditron .radiologist.index #grid3 .k-grid-content colgroup col:nth-child(2), .meditron .radiologist.index #grid4 .k-grid-header colgroup col:nth-child(2), .meditron .radiologist.index #grid4 .k-grid-content colgroup col:nth-child(2), .meditron .radiologist.index#grid5 .k-grid-header colgroup col:nth-child(2), .meditron .radiologist.index#grid5 .k-grid-content colgroup col:nth-child(2) {
  width: 12.9%;
}

.meditron .radiologist.index #grid .k-grid-header colgroup col:nth-child(4), .meditron .radiologist.index #grid .k-grid-content colgroup col:nth-child(4), .meditron .radiologist.index #grid1 .k-grid-header colgroup col:nth-child(4), .meditron .radiologist.index #grid1 .k-grid-content colgroup col:nth-child(4), .meditron .radiologist.index #grid2 .k-grid-header colgroup col:nth-child(4), .meditron .radiologist.index #grid2 .k-grid-content colgroup col:nth-child(4), .meditron .radiologist.index #grid3 .k-grid-header colgroup col:nth-child(4), .meditron .radiologist.index #grid3 .k-grid-content colgroup col:nth-child(4), .meditron .radiologist.index #grid4 .k-grid-header colgroup col:nth-child(4), .meditron .radiologist.index #grid4 .k-grid-content colgroup col:nth-child(4), .meditron .radiologist.index#grid5 .k-grid-header colgroup col:nth-child(4), .meditron .radiologist.index#grid5 .k-grid-content colgroup col:nth-child(4) {
  width: 10%;
}

.meditron .radiologist.index #grid .k-grid-header colgroup col:nth-child(5), .meditron .radiologist.index #grid .k-grid-content colgroup col:nth-child(5), .meditron .radiologist.index #grid1 .k-grid-header colgroup col:nth-child(5), .meditron .radiologist.index #grid1 .k-grid-content colgroup col:nth-child(5), .meditron .radiologist.index #grid2 .k-grid-header colgroup col:nth-child(5), .meditron .radiologist.index #grid2 .k-grid-content colgroup col:nth-child(5), .meditron .radiologist.index #grid3 .k-grid-header colgroup col:nth-child(5), .meditron .radiologist.index #grid3 .k-grid-content colgroup col:nth-child(5), .meditron .radiologist.index #grid4 .k-grid-header colgroup col:nth-child(5), .meditron .radiologist.index #grid4 .k-grid-content colgroup col:nth-child(5), .meditron .radiologist.index#grid5 .k-grid-header colgroup col:nth-child(5), .meditron .radiologist.index#grid5 .k-grid-content colgroup col:nth-child(5) {
  width: 8.8%;
}

.meditron .radiologist.index #grid .k-grid-header colgroup col:nth-child(6), .meditron .radiologist.index #grid .k-grid-content colgroup col:nth-child(6), .meditron .radiologist.index #grid1 .k-grid-header colgroup col:nth-child(6), .meditron .radiologist.index #grid1 .k-grid-content colgroup col:nth-child(6), .meditron .radiologist.index #grid2 .k-grid-header colgroup col:nth-child(6), .meditron .radiologist.index #grid2 .k-grid-content colgroup col:nth-child(6), .meditron .radiologist.index #grid3 .k-grid-header colgroup col:nth-child(6), .meditron .radiologist.index #grid3 .k-grid-content colgroup col:nth-child(6), .meditron .radiologist.index #grid4 .k-grid-header colgroup col:nth-child(6), .meditron .radiologist.index #grid4 .k-grid-content colgroup col:nth-child(6), .meditron .radiologist.index#grid5 .k-grid-header colgroup col:nth-child(6), .meditron .radiologist.index#grid5 .k-grid-content colgroup col:nth-child(6) {
  width: 12%;
}

.meditron .radiologist.index #grid .k-grid-header colgroup col:nth-child(9), .meditron .radiologist.index #grid .k-grid-content colgroup col:nth-child(9), .meditron .radiologist.index #grid1 .k-grid-header colgroup col:nth-child(9), .meditron .radiologist.index #grid1 .k-grid-content colgroup col:nth-child(9), .meditron .radiologist.index #grid2 .k-grid-header colgroup col:nth-child(9), .meditron .radiologist.index #grid2 .k-grid-content colgroup col:nth-child(9), .meditron .radiologist.index #grid3 .k-grid-header colgroup col:nth-child(9), .meditron .radiologist.index #grid3 .k-grid-content colgroup col:nth-child(9), .meditron .radiologist.index #grid4 .k-grid-header colgroup col:nth-child(9), .meditron .radiologist.index #grid4 .k-grid-content colgroup col:nth-child(9), .meditron .radiologist.index#grid5 .k-grid-header colgroup col:nth-child(9), .meditron .radiologist.index#grid5 .k-grid-content colgroup col:nth-child(9) {
  width: 18.33%;
}

.meditron .radiologist.index #grid table th:nth-child(1), .meditron .radiologist.index #grid table td:nth-child(1), .meditron .radiologist.index #grid1 table th:nth-child(1), .meditron .radiologist.index #grid1 table td:nth-child(1), .meditron .radiologist.index #grid2 table th:nth-child(1), .meditron .radiologist.index #grid2 table td:nth-child(1), .meditron .radiologist.index #grid3 table th:nth-child(1), .meditron .radiologist.index #grid3 table td:nth-child(1), .meditron .radiologist.index #grid4 table th:nth-child(1), .meditron .radiologist.index #grid4 table td:nth-child(1), .meditron .radiologist.index#grid5 table th:nth-child(1), .meditron .radiologist.index#grid5 table td:nth-child(1) {
  padding-left: 15px;
}

.meditron .radiologist.edit .patient-img {
  margin: 15px 0 0 0;
}

.meditron .transcriber.index #grid .k-grid-header colgroup col:nth-child(1), .meditron .transcriber.index #grid .k-grid-content colgroup col:nth-child(1) {
  width: 2%;
}

.meditron .transcriber.index #grid .k-grid-header colgroup col:nth-child(2), .meditron .transcriber.index #grid .k-grid-content colgroup col:nth-child(2) {
  width: 3%;
}

.meditron .transcriber.index #grid .k-grid-header colgroup col:nth-child(3), .meditron .transcriber.index #grid .k-grid-content colgroup col:nth-child(3) {
  width: 4%;
}

.meditron .transcriber.index #grid .k-grid-header colgroup col:nth-child(4), .meditron .transcriber.index #grid .k-grid-content colgroup col:nth-child(4) {
  width: 5%;
}

.meditron .transcriber.index #grid .k-grid-header colgroup col:nth-child(5), .meditron .transcriber.index #grid .k-grid-content colgroup col:nth-child(5) {
  width: 5%;
}

.meditron .transcriber.index #grid .k-grid-header colgroup col:nth-child(6), .meditron .transcriber.index #grid .k-grid-content colgroup col:nth-child(6) {
  width: 3%;
}

.meditron .transcriber.index #grid .k-grid-header colgroup col:nth-child(7), .meditron .transcriber.index #grid .k-grid-content colgroup col:nth-child(7) {
  width: 3%;
}

.meditron .transcriber.index #grid .k-grid-header colgroup col:nth-child(8), .meditron .transcriber.index #grid .k-grid-content colgroup col:nth-child(8) {
  width: 5%;
}

.meditron .transcriber.index #grid .k-grid-header colgroup col:nth-child(9), .meditron .transcriber.index #grid .k-grid-content colgroup col:nth-child(9) {
  width: 4%;
}

.meditron .transcriber.index #grid .k-grid-header colgroup col:nth-child(10), .meditron .transcriber.index #grid .k-grid-content colgroup col:nth-child(10) {
  width: 6%;
}

.meditron .transcriber.index #grid .k-grid-header colgroup col:nth-child(11), .meditron .transcriber.index #grid .k-grid-content colgroup col:nth-child(11) {
  width: 4%;
}

.meditron .results.index #grid .k-grid-header colgroup col:nth-child(1), .meditron .results.index #grid .k-grid-content colgroup col:nth-child(1) {
  width: 2%;
}

.meditron .results.index #grid .k-grid-header colgroup col:nth-child(2), .meditron .results.index #grid .k-grid-content colgroup col:nth-child(2) {
  width: 3%;
}

.meditron .results.index #grid .k-grid-header colgroup col:nth-child(3), .meditron .results.index #grid .k-grid-content colgroup col:nth-child(3) {
  width: 5%;
}

.meditron .results.index #grid .k-grid-header colgroup col:nth-child(4), .meditron .results.index #grid .k-grid-content colgroup col:nth-child(4) {
  width: 5%;
}

.meditron .results.index #grid .k-grid-header colgroup col:nth-child(5), .meditron .results.index #grid .k-grid-content colgroup col:nth-child(5) {
  width: 3%;
}

.meditron .results.index #grid .k-grid-header colgroup col:nth-child(6), .meditron .results.index #grid .k-grid-content colgroup col:nth-child(6) {
  width: 3%;
}

.meditron .results.index #grid .k-grid-header colgroup col:nth-child(7), .meditron .results.index #grid .k-grid-content colgroup col:nth-child(7) {
  width: 5%;
}

.meditron .results.index #grid .k-grid-header colgroup col:nth-child(8), .meditron .results.index #grid .k-grid-content colgroup col:nth-child(8) {
  width: 4%;
}

.meditron .results.index #grid .k-grid-header colgroup col:nth-child(9), .meditron .results.index #grid .k-grid-content colgroup col:nth-child(9) {
  width: 6%;
}

.meditron .results.index #grid .k-grid-header colgroup col:nth-child(10), .meditron .results.index #grid .k-grid-content colgroup col:nth-child(10) {
  width: 4%;
}

/*
** Banner
*/
#main-content div.message_board {
  padding-right: 0;
  padding-left: 0;
}

/*
** Charts
*/
svg {
  left: -7px;
  width: auto;
}

#donut-chart1, #donut-chart2, #donut-chart3 {
  height: 200px;
  width: 100%;
  position: absolute;
  right: 8%;
  margin-top: 20%;
}

.c-gray {
  font-size: 0.8em;
}

body#app-layout .panel-body div.message_board p {
  line-height: 1.5em;
  text-align: justify;
  font-weight: bold;
}

div.message_board {
  border-bottom: 1px solid #37a1d5;
  margin-top: 50px;
}

div.message_board p {
  height: 50px;
  overflow-y: auto;
  vertical-align: middle;
  padding-right: 10px;
}

#home .panel-carrousel {
  margin-bottom: 4%;
  width: 100%;
}

#message_panel .panel-body.bg-white {
  padding: 0;
  padding-bottom: 30px;
  height: 200px;
}

#message_panel .carousel-indicators {
  bottom: 10px;
}

#message_panel .carousel-indicators li {
  border: 1px solid #203d4f;
}

#message_panel .carousel-indicators .active {
  background-color: #0079c2;
}

div.ui-shadow {
  display: none;
}

#myCarousel div.carousel-inner img {
  width: 100%;
  border-radius: 4px;
}

.carousel-control.right {
  background: none;
}

.carousel-control.left {
  background: none;
}

.mail-image {
  margin-top: 50px;
}

.alert-content {
  width: 80px;
  height: 80px;
  display: inline-block;
}

.normal-alert-message {
  background: #ffffff url(/images/alerta_general.png) no-repeat;
}

.important-alert-message {
  background: #ffffff url(/images/alerta_importante.png) no-repeat;
}

.full-alert-message {
  background: #ffffff url(/images/alerta_pendiente.png) no-repeat;
}

.charts .panel-heading {
  padding: 30px 15px 30px 30px;
}

.charts .panel-body .legend {
  padding-top: 20px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  max-width: 220px;
  border-bottom: solid 1px #37a1d5;
}

.charts .panel-body h4 {
  font-size: 1.1428571429em;
  font-weight: 300;
}

.charts .panel-body h4 span {
  float: right;
  font-weight: bold;
}

.charts .panel-body .leyenda1 {
  border-left-width: 7px;
}

.charts .panel-body .leyenda2 {
  border-left-width: 7px;
}

.charts .panel-body .leyenda3 {
  border-left-width: 7px;
}

.charts .panel-body .leyenda4 {
  border-left-width: 7px;
}

.charts .panel-body .leyenda5 {
  border-left-width: 7px;
}

.charts .panel-body .leyenda6 {
  border-left-width: 7px;
}

.charts .panel-body .leyenda7 {
  border-left-width: 7px;
}

.charts .panel-body .leyenda8 {
  border-left-width: 7px;
}

.charts .panel-body .leyenda9 {
  border-left-width: 7px;
}

.charts .panel-body .leyenda10 {
  border-left-width: 7px;
}

#birth_date-error {
  white-space: initial !important;
}

.navbar i.dashboard-icon {
  font-size: 1.1428571429em;
  margin: 0 5px 0 0;
  color: #0079c2;
}

.navbar i.dashboard-icon.icon-engine {
  color: #1c3e4e;
  font-size: 1.7142857143em;
  margin: 0;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 10px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  background: red;
  font-weight: bold;
}

#numberMessages {
  margin-top: 2px;
  margin-left: 2px;
  display: none;
}

#user-logout i {
  font-size: 1.7142857143em;
  color: #0079c2;
}

#user-logout i:hover {
  color: #333;
}

.config-header ul.dropdown-menu {
  border-bottom: 4px solid #0079C2;
  cursor: auto;
}

.config-header ul.dropdown-menu li {
  cursor: auto;
}

.config-header ul.dropdown-menu li a {
  color: #0079c2;
}

.config-header ul.dropdown-menu li a:hover {
  background-color: transparent;
  color: #1c3e4e;
}

.config-header ul.dropdown-menu .dropdown-footer i {
  font-size: 1.7142857143em;
}

.config-header ul.dropdown-menu .dropdown-footer a {
  padding: 0 10px;
}

.config-header ul.dropdown-menu .bootstrap-select {
  width: 100%;
}

.config-header ul.dropdown-menu .bootstrap-select:focus {
  outline: 5px solid #1c3e4e;
}

.config-header ul.dropdown-menu .bootstrap-select .dropdown-menu {
  width: 100%;
}

.config-header ul.dropdown-menu .notifications-container {
  padding: 25px 25px 20px 25px;
  height: auto;
  max-height: 300px;
  overflow-y: auto;
}

.config-header ul.dropdown-menu .notifications-container .content-message-header {
  border-bottom: 1px solid #DFE5E9;
}

.config-header ul.dropdown-menu .notifications-container .content-message-header:not(:first-child) {
  padding-top: 15px;
}

.config-header ul.dropdown-menu .notifications-container .content-message-header .message-text {
  padding-bottom: 15px;
}

.config-header ul.dropdown-menu .notifications-container .alert-content {
  width: 40px;
  height: 40px;
  margin-left: -10px;
  margin-bottom: 7px;
}

.config-header ul.dropdown-menu .notifications-container .normal-alert-message,
.config-header ul.dropdown-menu .notifications-container .important-alert-message,
.config-header ul.dropdown-menu .notifications-container .full-alert-message {
  background-size: 40px 40px;
}

#notification-header .c-text {
  line-height: 1.8em;
}

#clave {
  border-right: 1px solid #DFE5E9;
}

.open .dropdown-toggle.btn-default {
  background-color: #ffffff;
  border: solid 2px #e3e4e8;
}

.header-menu .dropdown-menu {
  top: 42px;
  right: 10px;
  left: auto;
  max-width: 650px !important;
  width: 650px;
  min-width: 170px !important;
  margin: 0;
  padding: 0;
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
}

.header-menu .dropdown-menu:after {
  border-bottom: 6px solid #0079C2;
}

.header-menu li:hover {
  cursor: auto;
}

/* Info template */
.k-notification-info.k-group {
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
}

.k-widget.k-notification.k-popup.k-group.k-reset.k-state-border-up {
  position: relative !important;
}

.approve-orders {
  width: 300px;
  height: 115px;
}

.approve-orders h3 {
  font-size: 1em;
  padding: 32px 10px 5px;
}

.approve-orders i {
  float: left;
  margin: 30px 15px 30px 30px;
}

.k-notification {
  border: 0;
}

.navbar {
  height: 40px;
  min-height: 40px;
}

.navbar a.sidebar-toggle {
  padding: 2px;
  margin: 5px 10px;
}

.navbar > .container-fluid .navbar-brand {
  margin: 0 0 0 15px;
}

.container-fluid > .navbar-collapse, .container-fluid > .navbar-header, .container > .navbar-collapse, .container > .navbar-header {
  margin: 0;
}

.navbar-inverse {
  background-color: #ebebeb;
  border-bottom: 0;
}

.navbar-inverse .navbar-nav > .open > a {
  background-color: transparent;
}

.navbar-inverse .navbar-nav > .open > a:focus, .navbar-inverse .navbar-nav > .open > a:hover {
  background-color: transparent;
}

.navbar-inverse .navbar-brand {
  background: url("/images/logo_tope.png") no-repeat center;
  background-size: 184px;
  position: relative;
  width: 184px;
  height: 40px;
  padding: 0;
}

.navbar-inverse .navbar-toggle {
  float: left;
  margin: 10px;
  margin-top: 2.5px;
}

.navbar-header {
  float: left;
  width: 30%;
}

.navbar-collapse {
  float: right;
  width: 60%;
}

.navbar-collapse .header-menu {
  font-size: 1em;
  margin: 0;
}

@media (max-width: 1070px) {
  .navbar-collapse li#fecha-header,
  .navbar-collapse li#hora-header {
    display: none;
  }
}

li.config-header > a {
  padding: 5px 20px;
}

li#hora-header span:not(#reloj) {
  padding-left: 15px;
  border-left: 1px solid #0079c2;
  margin-left: 10px;
}

li#user-header {
  padding-right: 10px;
}

li#hora-header {
  padding-right: 50px;
}

li#fecha-header {
  padding-right: 5px;
}

span.username.c-text {
  line-height: 2.9em;
}

.separador {
  padding-left: 8px;
  border-left: 3px solid #0079c2;
}

.c-text {
  color: #1c3e4e;
  font-weight: 600;
  line-height: 2.9em;
  padding: 3px 0 3px 8px;
}

span#arrow-docent-file:hover {
  border-left: none;
}

table tr td:last-child span.slash {
  display: inline-block;
  font-size: 1.7142857143em;
  margin: 0 5px;
}

.hidden {
  display: none;
}

.k-grid-header:first-child th.k-header:first-child {
  border-radius: 0;
}

.k-header > .k-grid-filter {
  padding: 5px 5px 5px 3px;
  position: absolute;
  left: 0;
}

div#birad-table table thead tr th {
  padding-left: 40px;
}

div.normal-table table, div#grid table, div#grid1 table, div#grid2 table, div#grid3 table, div#grid4 table, div#grid5 table {
  border: 0;
  box-shadow: 0 4px 2px -2px #e8e8e8;
}

div.normal-table table thead tr th, div#grid table thead tr th, div#grid1 table thead tr th, div#grid2 table thead tr th, div#grid3 table thead tr th, div#grid4 table thead tr th, div#grid5 table thead tr th {
  background-color: #a4aeb9;
  border: 0;
  color: #ffffff;
  font-weight: bold;
  font-size: 1em;
  text-transform: none;
  padding-left: 24px;
  position: relative;
}

div.normal-table table thead tr th.changeable, div#grid table thead tr th.changeable, div#grid1 table thead tr th.changeable, div#grid2 table thead tr th.changeable, div#grid3 table thead tr th.changeable, div#grid4 table thead tr th.changeable, div#grid5 table thead tr th.changeable {
  background-color: rgba(164, 174, 185, 0.85);
}

div.normal-table table thead tr th a, div#grid table thead tr th a, div#grid1 table thead tr th a, div#grid2 table thead tr th a, div#grid3 table thead tr th a, div#grid4 table thead tr th a, div#grid5 table thead tr th a {
  color: #ffffff;
  font-weight: bold;
  font-size: 1em;
}

div.normal-table table tbody a, div#grid table tbody a, div#grid1 table tbody a, div#grid2 table tbody a, div#grid3 table tbody a, div#grid4 table tbody a, div#grid5 table tbody a {
  color: #0079c2;
}

div.normal-table table tbody a:hover, div#grid table tbody a:hover, div#grid1 table tbody a:hover, div#grid2 table tbody a:hover, div#grid3 table tbody a:hover, div#grid4 table tbody a:hover, div#grid5 table tbody a:hover {
  color: #1c3e4e;
  text-decoration: none;
}

div.normal-table table tbody a.btn, div#grid table tbody a.btn, div#grid1 table tbody a.btn, div#grid2 table tbody a.btn, div#grid3 table tbody a.btn, div#grid4 table tbody a.btn, div#grid5 table tbody a.btn {
  font-size: 0.8571428571em;
  padding: 3px 5px 3px 2.5px;
  margin-left: 0;
}

div.normal-table table tbody a.btn.btn-form:hover, div#grid table tbody a.btn.btn-form:hover, div#grid1 table tbody a.btn.btn-form:hover, div#grid2 table tbody a.btn.btn-form:hover, div#grid3 table tbody a.btn.btn-form:hover, div#grid4 table tbody a.btn.btn-form:hover, div#grid5 table tbody a.btn.btn-form:hover {
  color: #ffffff;
  background-color: #0079c2;
  border-color: #0079c2;
  border-radius: 0;
  -webkit-box-shadow: 0 0 5px #585858;
  -moz-box-shadow: 0 0 5px #585858;
  -o-box-shadow: 0 0 5px #585858;
  box-shadow: 0 0 5px #585858;
}

div.normal-table table tbody a.btn.btn-form:hover i, div#grid table tbody a.btn.btn-form:hover i, div#grid1 table tbody a.btn.btn-form:hover i, div#grid2 table tbody a.btn.btn-form:hover i, div#grid3 table tbody a.btn.btn-form:hover i, div#grid4 table tbody a.btn.btn-form:hover i, div#grid5 table tbody a.btn.btn-form:hover i {
  color: #ffffff;
}

div.normal-table table tbody a.btn.btn-form:focus, div#grid table tbody a.btn.btn-form:focus, div#grid1 table tbody a.btn.btn-form:focus, div#grid2 table tbody a.btn.btn-form:focus, div#grid3 table tbody a.btn.btn-form:focus, div#grid4 table tbody a.btn.btn-form:focus, div#grid5 table tbody a.btn.btn-form:focus {
  outline: 0;
  color: #0079c2;
  background-color: #ffffff;
  border-color: #0079c2;
  border-radius: 0;
  -webkit-box-shadow: 0 0 5px #585858;
  -moz-box-shadow: 0 0 5px #585858;
  -o-box-shadow: 0 0 5px #585858;
  box-shadow: 0 0 5px #585858;
}

div.normal-table table tbody a.btn i, div#grid table tbody a.btn i, div#grid1 table tbody a.btn i, div#grid2 table tbody a.btn i, div#grid3 table tbody a.btn i, div#grid4 table tbody a.btn i, div#grid5 table tbody a.btn i {
  color: #0079c2;
  padding: 0 2.5px;
}

div.normal-table table tbody a.btn i:hover, div#grid table tbody a.btn i:hover, div#grid1 table tbody a.btn i:hover, div#grid2 table tbody a.btn i:hover, div#grid3 table tbody a.btn i:hover, div#grid4 table tbody a.btn i:hover, div#grid5 table tbody a.btn i:hover {
  color: #ffffff;
}

div.normal-table table tbody a i, div#grid table tbody a i, div#grid1 table tbody a i, div#grid2 table tbody a i, div#grid3 table tbody a i, div#grid4 table tbody a i, div#grid5 table tbody a i {
  color: #989898;
  font-size: 1.2857142857em;
}

div.normal-table table tbody a i:hover, div#grid table tbody a i:hover, div#grid1 table tbody a i:hover, div#grid2 table tbody a i:hover, div#grid3 table tbody a i:hover, div#grid4 table tbody a i:hover, div#grid5 table tbody a i:hover {
  color: #0079c2;
}

div.normal-table table tbody a i.active, div#grid table tbody a i.active, div#grid1 table tbody a i.active, div#grid2 table tbody a i.active, div#grid3 table tbody a i.active, div#grid4 table tbody a i.active, div#grid5 table tbody a i.active {
  color: #0079c2;
}

div.normal-table table tbody a i.active:hover, div#grid table tbody a i.active:hover, div#grid1 table tbody a i.active:hover, div#grid2 table tbody a i.active:hover, div#grid3 table tbody a i.active:hover, div#grid4 table tbody a i.active:hover, div#grid5 table tbody a i.active:hover {
  color: #989898;
}

div.normal-table table tr:hover .customer-photo, div#grid table tr:hover .customer-photo, div#grid1 table tr:hover .customer-photo, div#grid2 table tr:hover .customer-photo, div#grid3 table tr:hover .customer-photo, div#grid4 table tr:hover .customer-photo, div#grid5 table tr:hover .customer-photo {
  -webkit-box-shadow: 0 0 5px rgba(109, 109, 109, 0.71);
  -moz-box-shadow: 0 0 5px rgba(109, 109, 109, 0.71);
  -o-box-shadow: 0 0 5px rgba(109, 109, 109, 0.71);
  box-shadow: 0 0 5px rgba(109, 109, 109, 0.71);
  margin-right: 10px;
}

div.normal-table table td, div#grid table td, div#grid1 table td, div#grid2 table td, div#grid3 table td, div#grid4 table td, div#grid5 table td {
  border: 0;
  color: #989898;
  font-size: 1em;
  font-family: "Open Sans", sans-serif, "DejaVu Sans", "sans-serif", "serif";
  padding-left: 25px;
  font-weight: 400;
}

div.normal-table table td.changeable, div#grid table td.changeable, div#grid1 table td.changeable, div#grid2 table td.changeable, div#grid3 table td.changeable, div#grid4 table td.changeable, div#grid5 table td.changeable {
  color: #0079C2;
  background-color: #EEEEEE;
  border-left: 2px solid #0079C2;
  border-right: 2px solid #0079C2;
}

div.normal-table table td.font-awesome-td, div#grid table td.font-awesome-td, div#grid1 table td.font-awesome-td, div#grid2 table td.font-awesome-td, div#grid3 table td.font-awesome-td, div#grid4 table td.font-awesome-td, div#grid5 table td.font-awesome-td {
  font-family: "FontAwesome", 'serif';
}

.k-pager-wrap .k-link {
  border-radius: 4px;
  border-color: #989898;
}

.k-pager-wrap .k-link:nth-child(2) {
  border-right: solid 1px #989898;
}

#room_scheduler {
  margin-top: 25px;
}

#main-content .nav-tabs h1.tab-title {
  color: #0079c2;
}

.title h1 a {
  color: #ffffff;
}

.table {
  font-family: 'Open sans', 'Helvetica', serif;
}

.table th {
  text-transform: none;
  color: #ffffff;
  background-color: #989898;
}

.table td {
  color: #989898;
}

.k-grid-toolbar .k-button {
  float: right;
}

.meditron #ReceptionAddForm .reception.add .content-scan-file-take, .meditron #ReceptionAddForm .reception.edit .content-scan-file-take, .meditron #ReceptionAddForm .patients.add .content-scan-file-take, .meditron #ReceptionAddForm .patients.edit .content-scan-file-take, .meditron #PatientAddForm .reception.add .content-scan-file-take, .meditron #PatientAddForm .reception.edit .content-scan-file-take, .meditron #PatientAddForm .patients.add .content-scan-file-take, .meditron #PatientAddForm .patients.edit .content-scan-file-take, .meditron #PatientEditForm .reception.add .content-scan-file-take, .meditron #PatientEditForm .reception.edit .content-scan-file-take, .meditron #PatientEditForm .patients.add .content-scan-file-take, .meditron #PatientEditForm .patients.edit .content-scan-file-take {
  padding-left: 10px;
}

.meditron #ReceptionAddForm .reception.add .content-scan-file-take .item-img-scan-file, .meditron #ReceptionAddForm .reception.edit .content-scan-file-take .item-img-scan-file, .meditron #ReceptionAddForm .patients.add .content-scan-file-take .item-img-scan-file, .meditron #ReceptionAddForm .patients.edit .content-scan-file-take .item-img-scan-file, .meditron #PatientAddForm .reception.add .content-scan-file-take .item-img-scan-file, .meditron #PatientAddForm .reception.edit .content-scan-file-take .item-img-scan-file, .meditron #PatientAddForm .patients.add .content-scan-file-take .item-img-scan-file, .meditron #PatientAddForm .patients.edit .content-scan-file-take .item-img-scan-file, .meditron #PatientEditForm .reception.add .content-scan-file-take .item-img-scan-file, .meditron #PatientEditForm .reception.edit .content-scan-file-take .item-img-scan-file, .meditron #PatientEditForm .patients.add .content-scan-file-take .item-img-scan-file, .meditron #PatientEditForm .patients.edit .content-scan-file-take .item-img-scan-file {
  margin-top: 5%;
  padding-left: 0;
}

.meditron #ReceptionAddForm .reception.add .content-scan-file-take .item-img-scan-file img, .meditron #ReceptionAddForm .reception.edit .content-scan-file-take .item-img-scan-file img, .meditron #ReceptionAddForm .patients.add .content-scan-file-take .item-img-scan-file img, .meditron #ReceptionAddForm .patients.edit .content-scan-file-take .item-img-scan-file img, .meditron #PatientAddForm .reception.add .content-scan-file-take .item-img-scan-file img, .meditron #PatientAddForm .reception.edit .content-scan-file-take .item-img-scan-file img, .meditron #PatientAddForm .patients.add .content-scan-file-take .item-img-scan-file img, .meditron #PatientAddForm .patients.edit .content-scan-file-take .item-img-scan-file img, .meditron #PatientEditForm .reception.add .content-scan-file-take .item-img-scan-file img, .meditron #PatientEditForm .reception.edit .content-scan-file-take .item-img-scan-file img, .meditron #PatientEditForm .patients.add .content-scan-file-take .item-img-scan-file img, .meditron #PatientEditForm .patients.edit .content-scan-file-take .item-img-scan-file img {
  padding-left: 0;
}

.meditron #ReceptionAddForm .reception.add .content-scan-file-take .item-img-scan-file .btn-delete-scan-file, .meditron #ReceptionAddForm .reception.edit .content-scan-file-take .item-img-scan-file .btn-delete-scan-file, .meditron #ReceptionAddForm .patients.add .content-scan-file-take .item-img-scan-file .btn-delete-scan-file, .meditron #ReceptionAddForm .patients.edit .content-scan-file-take .item-img-scan-file .btn-delete-scan-file, .meditron #PatientAddForm .reception.add .content-scan-file-take .item-img-scan-file .btn-delete-scan-file, .meditron #PatientAddForm .reception.edit .content-scan-file-take .item-img-scan-file .btn-delete-scan-file, .meditron #PatientAddForm .patients.add .content-scan-file-take .item-img-scan-file .btn-delete-scan-file, .meditron #PatientAddForm .patients.edit .content-scan-file-take .item-img-scan-file .btn-delete-scan-file, .meditron #PatientEditForm .reception.add .content-scan-file-take .item-img-scan-file .btn-delete-scan-file, .meditron #PatientEditForm .reception.edit .content-scan-file-take .item-img-scan-file .btn-delete-scan-file, .meditron #PatientEditForm .patients.add .content-scan-file-take .item-img-scan-file .btn-delete-scan-file, .meditron #PatientEditForm .patients.edit .content-scan-file-take .item-img-scan-file .btn-delete-scan-file {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  display: none;
  color: #cbdbe6;
}

.meditron #ReceptionAddForm .reception.add .content-scan-file-take .item-img-scan-file:hover .btn-delete-scan-file, .meditron #ReceptionAddForm .reception.edit .content-scan-file-take .item-img-scan-file:hover .btn-delete-scan-file, .meditron #ReceptionAddForm .patients.add .content-scan-file-take .item-img-scan-file:hover .btn-delete-scan-file, .meditron #ReceptionAddForm .patients.edit .content-scan-file-take .item-img-scan-file:hover .btn-delete-scan-file, .meditron #PatientAddForm .reception.add .content-scan-file-take .item-img-scan-file:hover .btn-delete-scan-file, .meditron #PatientAddForm .reception.edit .content-scan-file-take .item-img-scan-file:hover .btn-delete-scan-file, .meditron #PatientAddForm .patients.add .content-scan-file-take .item-img-scan-file:hover .btn-delete-scan-file, .meditron #PatientAddForm .patients.edit .content-scan-file-take .item-img-scan-file:hover .btn-delete-scan-file, .meditron #PatientEditForm .reception.add .content-scan-file-take .item-img-scan-file:hover .btn-delete-scan-file, .meditron #PatientEditForm .reception.edit .content-scan-file-take .item-img-scan-file:hover .btn-delete-scan-file, .meditron #PatientEditForm .patients.add .content-scan-file-take .item-img-scan-file:hover .btn-delete-scan-file, .meditron #PatientEditForm .patients.edit .content-scan-file-take .item-img-scan-file:hover .btn-delete-scan-file {
  display: inherit;
}

.meditron #ReceptionAddForm .reception.add .content-scan-file-take .item-img-scan-file:hover .btn-delete-scan-file:hover, .meditron #ReceptionAddForm .reception.edit .content-scan-file-take .item-img-scan-file:hover .btn-delete-scan-file:hover, .meditron #ReceptionAddForm .patients.add .content-scan-file-take .item-img-scan-file:hover .btn-delete-scan-file:hover, .meditron #ReceptionAddForm .patients.edit .content-scan-file-take .item-img-scan-file:hover .btn-delete-scan-file:hover, .meditron #PatientAddForm .reception.add .content-scan-file-take .item-img-scan-file:hover .btn-delete-scan-file:hover, .meditron #PatientAddForm .reception.edit .content-scan-file-take .item-img-scan-file:hover .btn-delete-scan-file:hover, .meditron #PatientAddForm .patients.add .content-scan-file-take .item-img-scan-file:hover .btn-delete-scan-file:hover, .meditron #PatientAddForm .patients.edit .content-scan-file-take .item-img-scan-file:hover .btn-delete-scan-file:hover, .meditron #PatientEditForm .reception.add .content-scan-file-take .item-img-scan-file:hover .btn-delete-scan-file:hover, .meditron #PatientEditForm .reception.edit .content-scan-file-take .item-img-scan-file:hover .btn-delete-scan-file:hover, .meditron #PatientEditForm .patients.add .content-scan-file-take .item-img-scan-file:hover .btn-delete-scan-file:hover, .meditron #PatientEditForm .patients.edit .content-scan-file-take .item-img-scan-file:hover .btn-delete-scan-file:hover {
  color: white;
}

.input-fill, input:-webkit-autofill:focus, input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

#main-content #login-block {
  margin-top: 3%;
  padding: 0;
}

#main-content .login-box {
  -webkit-filter: drop-shadow(0px 2px 0px #e3e4e8);
  width: 350px;
  margin: 0 auto;
  top: 20px;
}

#main-content .login-box label {
  color: #6c6c6c;
  font-weight: 300;
  font-size: 14px;
}

#main-content .login-box .login-form input {
  color: #0079c2;
  font-size: 0.8571428571em;
  margin-bottom: 5px;
}

#main-content .login-box .login-form label {
  font-size: 0.9285714286em;
}

#main-content .login-box .login-form a, #main-content .login-box .login-form .k-in {
  color: #6c6c6c;
  font-size: 0.8214285714em;
  opacity: 1;
  cursor: pointer;
}

#main-content .login-box .login-form a:hover, #main-content .login-box .login-form .k-in:hover {
  color: #0079c2;
}

#main-content .login-box .login-form #submit-form {
  font-size: 1em;
}

#main-content .login-box .login-form #submit-form:hover {
  color: #ffffff;
  background-color: #0079c2;
}

.remember-links {
  margin-top: 25px;
  padding-left: 35px;
  padding-right: 35px;
}

.remember-links .col-md-6 {
  padding-left: 0;
  padding-right: 0;
}

.remember-links .col-md-6:nth-child(1) {
  border-right: solid 2px #0079c2;
  text-align: left;
}

.remember-links .col-md-6:nth-child(2) {
  text-align: center;
}

.remember-links .login-check {
  top: 3px;
  color: #6c6c6c;
  font-size: 0.8214285714em;
  font-weight: inherit !important;
  margin-top: 0% !important;
}

.remember-links .login-check .check-login {
  font-weight: inherit;
}

.remember-links .login-check #check-awesome {
  font-weight: inherit !important;
  margin-top: 0% !important;
}

.btn.btn-login {
  display: block;
  border: 2px solid #0079c2;
  color: #0079c2;
  background: transparent;
  border-radius: 4px;
}

.btn.btn-login:hover {
  color: #ffffff;
  background-color: #0079c2;
}

#main-content #service_request .panel-body {
  padding: 26px;
}

#main-content #service_request .bootstrap-select.btn-group:not(.input-group-btn),
#main-content #service_request .bootstrap-select.btn-group[class*="span"] {
  margin-bottom: 0;
}

#main-content ul.bootstrap-select {
  margin-left: 0;
}

/*::::::::::::::::::  Reception -> Service Request  ::::::::::::::::::::::*/
.referring-add-btn {
  border-radius: 4px;
  background-color: transparent;
  color: #0079c2;
  float: inherit;
  padding: 9px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 2px solid #0079c2;
  margin-top: 5px;
}

.referring-add-btn:hover {
  color: #ffffff;
  background-color: #0079c2;
  border-color: #0079c2;
  border-radius: 4px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

.referring-add-btn:focus {
  outline: 0;
  color: #0079c2;
  background-color: #ffffff;
  border-color: #0079c2;
  border-radius: 4px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

/*::::::::::::::::::  Administration -> Configurations :::::::::::::::::::*/
#btn-daily-cron,
#btn-monthly-cron,
#btn-yearly-cron {
  margin: 5px 0;
}

#appointment_sms_notification {
  max-width: 156px;
  min-width: 56px;
}

#mammography_reminder {
  max-width: 50%;
  min-width: 56px;
}

#birad-table {
  padding-top: 50px;
}

#birad-table table thead tr th {
  background-color: #203d4f;
}

#birad-table table thead tr th:first-child {
  visibility: hidden;
}

#birad-table table tbody td {
  text-align: center;
}

#colorbox .col-md-offset-1 {
  margin-left: 70px;
}

#colorbox input.form-control, #colorbox select.form-control, #colorbox .btn-select.form-control {
  font-size: 14px;
  height: 42px;
  border: solid 2px #e3e4e8;
  width: 100%;
}

#colorbox input.form-control:focus, #colorbox select.form-control:focus, #colorbox .btn-select.form-control:focus {
  border: solid 2px #1c3e4e;
}

#colorbox input.form-control:hover, #colorbox select.form-control:hover, #colorbox .btn-select.form-control:hover {
  border-color: #aeaeae;
}

#colorbox .k-widget.k-datetimepicker .k-input.form-control,
#colorbox .k-widget.k-datepicker .k-input.form-control {
  border: none;
  height: 36px;
  padding: 0;
  box-shadow: none;
}

#colorbox .help-block {
  margin: 0;
}

#colorbox select[multiple].form-control {
  height: auto;
}

#colorbox legend:nth-child(1) {
  color: #0079c2;
  padding-left: 5px;
  padding-bottom: 5px;
  font-size: 1.5714285714em;
  font-weight: bold;
  border: none;
}

.form-control {
  box-shadow: none;
}

.kendo-label {
  margin-top: 19px;
}

.kendo-label > .k-in.label {
  padding: 0;
}

.container-fluid label, .container-fluid .label, .container label, .container .label {
  color: #768082;
  font-size: 1em;
  font-weight: normal;
  margin-top: 9px;
}

.container-fluid label.in-footer, .container-fluid .label.in-footer, .container label.in-footer, .container .label.in-footer {
  float: right;
  margin: 10px 0 10px 10px;
  line-height: 36px;
}

.container-fluid label.btn, .container label.btn {
  color: #0079c2;
}

.container-fluid label.btn:hover, .container label.btn:hover {
  background-color: #0079c2;
  color: #ffffff;
}

.patient-img, #patient-img-container {
  border: 2px solid #0079c2;
  border-radius: 4px;
  margin: 15px auto 5px;
}

.patient-img.patient-img-reception {
  margin: 0;
}

#patient-img-container {
  width: 305px;
  height: 305px;
  margin: 0 auto;
  overflow: hidden;
}

#patient-img-container img {
  height: 301px;
  width: 301px;
}

#patient-img-container .cropControls {
  top: 2px;
  right: 148px;
  border-radius: 2px;
}

#patient-img-container .cropControls i.cropControlUpload {
  border-radius: 2px;
}

.croppie-container {
  padding: 0;
}

.cropImgWrapper {
  top: 2px;
  border-radius: 2px;
}

#patient-canvas {
  display: none;
}

.k-widget.k-datepicker, .k-widget.k-datetimepicker {
  width: 100%;
}

.select-picker {
  font-family: "FontAwesome", "Open Sans", sans-serif;
}

.select-picker option {
  font-family: "FontAwesome", "Open Sans", sans-serif;
}

.btn-select {
  text-align: left;
  font-family: "FontAwesome", "Open Sans", sans-serif;
}

.btn-select span {
  color: #1c3e4e;
}

.btn-select:focus {
  border-color: #1c3e4e;
}

.dropdown-menu li {
  font-family: "FontAwesome", "Open Sans", sans-serif;
}

.dropdown-menu > li > a:hover {
  color: #0079c2;
  background-color: #e3e4e8;
}

#studies .panel-default > .panel-heading {
  padding: 0;
}

input[type="radio"] {
  margin-left: 25px;
  margin-top: -20px;
  z-index: 999;
  visibility: hidden;
}

input[type="radio"]:hover {
  cursor: pointer;
}

input[type="radio"]:after {
  border-radius: 25px;
  position: relative;
  background-color: #ffffff;
  color: #0079c2;
  content: '\f10c';
  display: inline-block;
  visibility: visible;
  font-family: FontAwesome;
}

input[type="radio"]:checked:after {
  border-radius: 25px;
  position: relative;
  background-color: #ffffff;
  color: #0079c2;
  content: '\f192';
  display: inline-block;
  visibility: visible;
  font-family: FontAwesome;
}

.k-checkbox:indeterminate + .k-checkbox-label:after {
  background-color: #37a1d5;
  left: 10px;
  top: -6px;
}

.k-checkbox-label:before {
  margin-top: -14px;
}

.remember-links .k-checkbox-label:before {
  margin-top: -11px;
}

/*#main-content
{

    input[type="checkbox"]
    {
        //display:none;
    }

    input[type="checkbox"] + label
    {
        display:inline-block;
        width:10px;
        height:25px;
        margin: 4px 0 0 0;
        vertical-align:middle;
        cursor:pointer;
    }

    input[type="checkbox"] + label:before
    {
        content: '';
        color: $blue-primary;
        background-color: $white;
        font-family: FontAwesome;
    }

    input[type="checkbox"]:checked + label:before
    {
        content: '\f00c';
        background-color: $blue-primary;
        color: $white;
        font-family: FontAwesome;
    }

    input[type="checkbox"]:checked + label:after {
        font-weight: bold;
    }
}*/
.add-edit-form .k-state-selected {
  background-color: #ffffff;
  color: #0079c2;
}

.add-edit-form .k-state-selected label {
  background-color: #ffffff;
  color: #0079c2;
}

div.k-treeview {
  overflow: inherit;
}

div.bootstrap-select.changeableFilter button.dropdown-toggle {
  border: 2px solid #0079C2;
}

div.bootstrap-select.changeableFilter button.dropdown-toggle:hover {
  border-color: #004872;
}

div.bootstrap-select button.dropdown-toggle {
  height: 42px;
  border: solid 2px #e3e4e8;
  background-color: #ffffff;
}

div.bootstrap-select button.dropdown-toggle:hover {
  border-color: #aeaeae;
}

div.bootstrap-select button.dropdown-toggle:focus {
  border: solid 2px #1c3e4e;
}

div.bootstrap-select button.form-control.btn-group {
  height: 42px;
}

div.bootstrap-select button span.filter-option {
  font-family: "FontAwesome", "Open Sans", sans-serif;
}

div.bootstrap-select.form-control.in-footer {
  float: right;
  width: auto;
  margin: 10px 0 10px 10px;
  min-height: auto;
}

div.bootstrap-select.form-control.in-footer > .btn {
  height: 37px;
}

div a, div a:focus, div a:hover, div a:active {
  outline: 0;
}

div .bootstrap-select.btn-group .dropdown-toggle.filter-option {
  outline: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

div .bootstrap-select .dropdown-toggle:focus,
div .bootstrap-select .form-control:focus {
  outline: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

div input.form-control, div input.form-control.k-input, div select.form-control, div select.form-control.k-input, div textarea.form-control, div textarea.form-control.k-input {
  font-size: 14px;
  height: 42px;
  border: solid 2px #e3e4e8;
  width: 100%;
}

div input.form-control:focus, div input.form-control.k-input:focus, div select.form-control:focus, div select.form-control.k-input:focus, div textarea.form-control:focus, div textarea.form-control.k-input:focus {
  border: solid 2px #1c3e4e;
}

div input.form-control:hover, div input.form-control.k-input:hover, div select.form-control:hover, div select.form-control.k-input:hover, div textarea.form-control:hover, div textarea.form-control.k-input:hover {
  border-color: #aeaeae;
}

div input.form-control.alert.alert-danger, div input.form-control.k-input.alert.alert-danger, div select.form-control.alert.alert-danger, div select.form-control.k-input.alert.alert-danger, div textarea.form-control.alert.alert-danger, div textarea.form-control.k-input.alert.alert-danger {
  border: solid 2px #a94442;
}

div textarea.form-control, div textarea.form-control.k-input {
  height: auto;
}

.bootstrap-select.btn-group:not(.input-group-btn), .bootstrap-select.btn-group[class*=col-] {
  min-height: 42px;
}

.k-popup .k-list .k-item {
  cursor: pointer;
}

.k-multiselect.form-control {
  cursor: pointer;
  font-size: 14px;
  border: none;
  height: 42px;
}

.k-multiselect.form-control:focus {
  border: solid 2px #1c3e4e;
}

.k-multiselect.k-header.k-state-focused {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-color: #e3e4e8;
}

.k-multiselect.k-state-border-down .k-multiselect-wrap {
  border: solid 2px #1c3e4e;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.k-multiselect-wrap {
  font-size: 14px;
  min-height: 38px;
  border: solid 2px #e3e4e8;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.k-multiselect-wrap:focus {
  border: solid 2px #1c3e4e;
}

.k-multiselect-wrap li {
  margin: 1px;
  padding: 6px 24px;
  line-height: 1.5em;
  float: left;
  position: relative;
}

.k-multiselect-wrap .k-select {
  top: 3px;
}

textarea.form-control {
  font-size: 14px;
  min-height: 84px;
  border: solid 2px #e3e4e8;
}

textarea.form-control:focus {
  border: solid 2px #1c3e4e;
}

span.help-block {
  font-size: 0.8571428571em;
  color: #a94442;
}

.bootstrap-select .dropdown-toggle:focus {
  outline: 0 !important;
}

.k-picker-wrap.k-state-default {
  border: solid 2px #e3e4e8;
  height: 36px;
  padding: 2px 30px 2px 2px;
  -webkit-transition: all 0.4s cubic-bezier(0.175, 0.575, 0.275, 1.175);
  -moz-transition: all 0.4s cubic-bezier(0.175, 0.575, 0.275, 1.175);
  -ms-transition: all 0.4s cubic-bezier(0.175, 0.575, 0.275, 1.175);
  -o-transition: all 0.4s cubic-bezier(0.175, 0.575, 0.275, 1.175);
  transition: all 0.4s cubic-bezier(0.175, 0.575, 0.275, 1.175);
}

.k-picker-wrap.k-state-default:hover {
  border-color: #aeaeae;
}

.k-picker-wrap.k-state-default.k-state-hover {
  background-color: #ffffff;
}

.k-picker-wrap.k-state-default.k-state-focused {
  border: solid 2px #1c3e4e;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
}

.k-widget.k-datepicker, .k-widget.k-datetimepicker {
  height: auto;
  border: none;
}

.k-widget.k-datepicker .k-input.form-control, .k-widget.k-datetimepicker .k-input.form-control {
  border: none;
  height: 36px;
  padding: 0;
  box-shadow: none;
}

.k-widget.k-datepicker .k-icon, .k-widget.k-datepicker .k-sprite, .k-widget.k-datepicker .k-tool-icon, .k-widget.k-datetimepicker .k-icon, .k-widget.k-datetimepicker .k-sprite, .k-widget.k-datetimepicker .k-tool-icon {
  margin-top: 10px;
  margin-left: 1px;
}

.k-upload.k-header {
  border: 2px solid #e3e4e8;
  background-color: #ffffff;
}

.k-upload .k-button.k-upload-button {
  color: #0079c2;
  border: 2px solid #0079c2;
  background-color: #ffffff;
  font-weight: 400;
}

.k-upload .k-button.k-upload-button:hover {
  color: #ffffff;
  background-color: #0079c2;
  border-color: #0079c2;
  border-radius: 4px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

.k-upload .k-button.k-upload-button:focus {
  outline: 0;
  color: #0079c2;
  background-color: #ffffff;
  border-color: #0079c2;
  border-radius: 4px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

.k-upload .k-upload-files {
  border-color: #e3e4e8;
  border-width: 1px 10px 0;
  padding-bottom: 0;
}

.k-upload .k-upload-files .k-file {
  border: none;
}

.k-upload .k-upload-files .k-file .k-file-name {
  max-width: 100%;
  white-space: normal;
}

body#app-layout #steps .panel-heading {
  padding: 0px 15px;
  background-color: #0079c2;
}

body#app-layout #steps h4.panel-title {
  font-size: 1.1428571429em;
  font-weight: 300 !important;
  color: #ffffff;
  padding: 10px;
}

body#app-layout #steps-count.badge {
  background-color: #ffffff;
  height: 30px;
  margin: 0;
  padding-top: 4px;
  float: right;
  display: block;
  min-width: 40px;
  font-size: 1.2857142857em;
  border: 2px solid #e3e4e8;
  color: #768082;
}

#signature-img-container, #logo-img-container, .signature-img, .logo-img {
  min-height: 40px;
  border: 2px solid #0079c2;
  border-radius: 4px;
  padding: 0;
  background-color: #e3e4e8;
}

.signature-img, .logo-img {
  position: relative;
}

.signature-img a.btn.btn-form, .logo-img a.btn.btn-form {
  position: absolute;
  bottom: 0;
  margin: 0;
  right: 0;
  border-radius: 0;
  color: #ffffff;
  background-color: #0079c2;
  opacity: 0.6;
}

.signature-img a.btn.btn-form:hover, .logo-img a.btn.btn-form:hover {
  box-shadow: none;
  opacity: 1;
  border-top-left-radius: 4px;
}

#signature-img-container, #logo-img-container {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

#signature-img-container.logo-dimensions, #logo-img-container.logo-dimensions {
  width: 465px;
  height: 200px;
}

#signature-img-container.report-header-dimensions, #logo-img-container.report-header-dimensions {
  width: 785px;
  height: 150px;
}

#signature-img-container.report-footer-dimensions, #logo-img-container.report-footer-dimensions {
  width: 785px;
  height: 100px;
}

#signature-img-container.email-img-dimensions, #logo-img-container.email-img-dimensions {
  width: 400px;
  height: 200px;
}

#signature-img-container.signature-dimensions, #logo-img-container.signature-dimensions {
  width: 465px;
  height: 200px;
}

.inline-button {
  margin-top: 30px;
}

#removeRequestedProcedure, #removeProcedureRoom {
  display: none;
}

.k-timepicker {
  width: 100%;
}

#docent-file-content {
  display: none;
}

#reportText {
  height: 107px;
}

.container-out {
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-radius: 4px;
  border-color: #e3e4e8;
}

.technician #TechnicianEditForm .panel .panel-body .patient-info {
  overflow: hidden;
}

/* basic css */
input[type=checkbox] {
  display: none;
}

#check-awesome {
  display: flex;
  width: 100%;
  color: #768082;
  font-weight: bolder;
  margin-top: 4%;
}

#check-awesome label {
  display: inline-block;
  width: 100%;
  height: 12px;
  margin: 0 auto;
}

#check-awesome label span {
  display: block;
  position: absolute;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

#check-awesome .ckeckbox-left {
  margin-right: 25px;
}

.box {
  border: 1px solid #CCCCCC;
  height: 14px;
  width: 14px;
  z-index: 2;
  -webkit-transition-delay: 0.1s;
  -moz-transition-delay: 0.1s;
  transition-delay: 0.1s;
  border-radius: 3px;
}

.check {
  width: 7px;
  height: 16px;
  border: 3px solid #0079C2;
  border-top: none;
  border-left: none;
  margin-left: 5px;
  margin-top: -2px;
  opacity: 0;
  z-index: 2;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

/* handling click events */
input[type=checkbox]:checked ~ label .box {
  opacity: 0;
  -webkit-transform: scale(0) rotate(-180deg);
  -moz-transform: scale(0) rotate(-180deg);
  transform: scale(0) rotate(-180deg);
}

input[type=checkbox]:checked ~ label .check {
  opacity: 1;
  -webkit-transform: scale(1) rotate(45deg);
  -moz-transform: scale(1) rotate(45deg);
  transform: scale(1) rotate(45deg);
}

/* bubble animation */
@-webkit-keyframes growCircle {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  70% {
    background: #DDD;
    -webkit-transform: scale(1.25);
  }
}

@-moz-keyframes growCircle {
  0%,
  100% {
    -moz-transform: scale(0);
  }
  70% {
    background: #DDD;
    -moz-transform: scale(1.25);
  }
}

@keyframes growCircle {
  0%,
  100% {
    transform: scale(0);
  }
  70% {
    background: #DDD;
    transform: scale(1.25);
  }
}

#cboxLoadedContent {
  width: 100% !important;
  height: 470px;
}

#cboxLoadedContent:hover {
  overflow-y: scroll !important;
}

#cboxLoadedContent select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff url("/images/arrowdown.png") no-repeat 98% 50%;
  color: #0079c2;
}

#cboxOverlay {
  background: #000000;
}

#cboxTopLeft, #cboxTopRight, #cboxBottomLeft, #cboxBottomRight, #cboxMiddleLeft, #cboxMiddleRight, #cboxTopCenter, #cboxBottomCenter, #cboxLoadingGraphic {
  display: none;
}

#cboxContent {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  overflow: hidden;
  width: 100% !important;
}

#cboxContent ::-webkit-scrollbar {
  width: 5px;
}

#cboxContent ::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #989898;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

#cboxContent ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #FFFFFF;
  border-radius: 10px;
}

#cboxContent .btn.btn-form {
  float: right;
}

#cboxContent .container-fluid .modal-header {
  border-color: rgba(0, 121, 194, 0.5);
}

#cboxContent .container-fluid .modal-footer {
  border-color: rgba(0, 121, 194, 0.8);
}

#cboxContent .container-fluid .row:nth-child(2), #cboxContent .container-fluid .row:last-child {
  position: fixed;
  z-index: 999;
  background-color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

#cboxContent .container-fluid .row:nth-child(2) {
  top: 0;
  width: 600px;
  height: 62px;
}

#cboxContent .container-fluid .row:nth-child(3) {
  margin-top: 77px;
}

#cboxContent .container-fluid .row:last-child {
  height: 87px;
  bottom: 0;
  width: 100%;
}

#cboxContent .container-fluid .row:nth-last-child(2) {
  margin-bottom: 40px;
}

#cboxClose {
  top: 15px;
  right: 10px;
  text-indent: inherit;
  background: none;
  color: #0079c2;
  font-size: 1.2857142857em;
  font-weight: bold;
  z-index: 90000;
}

#cboxClose.light {
  color: #ffffff;
}

#cboxClose:hover {
  color: #1c3e4e;
}

.fancy-shadow {
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 1680px) {
  .arrow-white {
    left: 97%;
  }
}

#scan-file-modal .modal-dialog {
  width: 64%;
}

#scan-file-modal .modal-dialog .modal-content .modal-body #container #videoContainer video#videoElement {
  height: auto;
  width: 100%;
}

#scan-file-modal .modal-dialog .modal-content .modal-body #container .content-canvas-to-crop {
  display: none;
}

#scan-file-modal .modal-dialog .modal-content .modal-body #container #videoControls {
  text-align: center;
}

#scan-file-modal .modal-dialog .modal-content .modal-body #container #canvas {
  width: 100%;
  height: auto;
}

#scan-file-modal .modal-dialog .modal-content .modal-body #container .content-img-crop {
  text-align: center;
}

#scan-file-modal .modal-dialog .modal-content .modal-body #container .content-img-crop #img_crop {
  width: 25%;
}
