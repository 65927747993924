/*:::::::::::::::: Buttons ::::::::::::::::::*/

/**
* button-state
* Used for hover, active, focus button states.
*

@params
* $bg-color: button's background color.
* $border-color: button's border color.
* $radius: button's border radius.
* $box-shadow: button's box shadow.
@endparams

**/

@mixin button-state($text-color,$bg-color,$border-color,$radius,$box-shadow){
    color: $text-color;
    background-color: $bg-color;
    border-color: $border-color;
    border-radius: $radius;
    -webkit-box-shadow: $box-shadow;
    -moz-box-shadow: $box-shadow;
    -o-box-shadow: $box-shadow;
    box-shadow: $box-shadow;
}

/*::::::::::::::: Transitions ::::::::::::::::*/

/**
* transition
* Used to avoid writing repetition of transitions.
*

@params
* $transition: transition settings.
@endparams

**/

@mixin transition($transition) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}