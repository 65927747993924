//This the patch to hide the eye icon
table {
    tr {
        td {
            &:last-child {
                span.slash {
                    display: inline-block;
                    font-size: 24px/$body-font-size+em;
                    margin: 0 5px;
                }
            }
        }
    }
}

.hidden
{
    display: none;
}

.k-grid-header:first-child th.k-header:first-child {
    border-radius: 0;
}

.k-header {
    > {
        .k-grid-filter {
            padding: 5px 5px 5px 3px;
            position: absolute;
            left: 0;
        }
    }
}

div {

	&#birad-table {
		table {
			thead tr th {
				padding-left: 40px;
			}
		}
	}

    &.normal-table, &#grid, &#grid1, &#grid2, &#grid3, &#grid4, &#grid5  {

        table {
            border: 0;
            box-shadow: 0 4px 2px -2px #e8e8e8;

            thead tr th {
                background-color: #a4aeb9;
                border: 0;
                color: $white;
                font-weight: bold;
                font-size: 14px/$body-font-size+em;
                text-transform: none;
                padding-left: 24px;
                position: relative;

	            &.changeable {
		            background-color: rgba(164, 174, 185, 0.85);
	            }

                a {
                    color: $white;
                    font-weight: bold;
                    font-size: 14px/$body-font-size+em;

                }
            }

            tbody {
                a {
                    color: $blue-primary;
                    &:hover {
                        color: $blue-strong-focus;
                        text-decoration: none;
                    }

                    &.btn {
                        font-size: 12px/$body-font-size+em;
                        padding: 3px 5px 3px 2.5px;
                        margin-left: 0;
                        $radius: 0;
                        &.btn-form {
                            &:hover {
                                @include button-state($white, $blue-primary, $blue-primary, $radius, $box-shadow);

                                i {
                                    color: $white;
                                }
                            }

                            &:focus {
                                outline: 0;
                                @include button-state($blue-primary, $white, $blue-primary, $radius, $box-shadow);
                            }
                        }

                        i {
                            color: $blue-primary;
                            padding: 0 2.5px;

                            &:hover {
                                color: $white;
                            }
                        }
                    }

                    i {
                        color: $grey-table;
                        font-size: 18px/$body-font-size+em;
                        &:hover {
                            color: $blue-primary;
                        }

                        &.active {
                            color: $blue-primary;
                            &:hover {
                                color: $grey-table;
                            }
                        }
                    }
                }
            }

            tr {
                &:hover {
                    .customer-photo {
                        -webkit-box-shadow: 0 0 5px rgba(109, 109, 109, 0.71);
                        -moz-box-shadow: 0 0 5px rgba(109, 109, 109, 0.71);
                        -o-box-shadow: 0 0 5px rgba(109, 109, 109, 0.71);
                        box-shadow: 0 0 5px rgba(109, 109, 109, 0.71);
                        margin-right: 10px;
                    }
                }
            }

            td {
                border: 0;
                color: $grey-table;
                font-size: 14px/$body-font-size+em;
                font-family: $body-font-family, 'DejaVu Sans', 'sans-serif' ,'serif';
                padding-left: 25px;
                font-weight: 400;

	            &.changeable {
                    color: #0079C2;
		            background-color: #EEEEEE;
                    border-left: 2px solid #0079C2;
                    border-right: 2px solid #0079C2;
	            }

                &.font-awesome-td {
	                font-family: "FontAwesome", 'serif';
                }
            }
        }
    }
}

.k-pager-wrap {
    .k-link {
        border-radius: 4px;
        border-color: $grey-table;

        &:nth-child(2) {
            border-right: solid 1px $grey-table;
        }
    }
}

#room_scheduler
{
    margin-top: 25px;

}
#main-content
{
    .nav-tabs
    {
        h1.tab-title
        {
            color: $blue-primary;
        }
    }
}

.title
{
    h1
    {
        a
        {
            color: $white;
        }
    }
}

.table
{
    font-family: 'Open sans', 'Helvetica', serif;
    th
    {
        text-transform: none;
        color:$white;
        background-color: $grey-table;
    }
    td
    {
        color: $grey-table;
    }
}

.k-grid-toolbar
{
    .k-button
    {
        float: right;
    }
}
