.sidebar-nav li.current a {
	border-left: 3px solid $blue-border-sidebar;
}

.arrow {
	padding-top: 0;
	position: absolute;
	top: 45%;

	&.glyphicon {
		right: 10px;
	}
}

.sidebar-large {

	#sidebar {
		overflow-y: scroll;
		width: 200px;
		left: -350px;

		.sidebar-nav {
			top: 0;
			list-style: none;
			padding: 0;
			margin-top: 54px;

			.ico, .dashboard-icon {
				color: $grey-icon;
			}

			> li {
				position: relative;
				display: block;
				text-align: center;
				width: 190px;
				&:hover {
					i {
						color: #fff;
					}
				}

				&:last-child {
					height: 88px;

					&:hover {
						background-color: $sidebar-grey-bg;
					}
				}

				a {
					position: relative;
					display: block;
					text-decoration: none;
					text-align: center;
					width: 100%;
					padding: 10px;

					&.docent-file-link
					{
						padding-left: 25px;
					}


					i {
						font-size: 40px/$body-font-size+em;
						text-align: center;
						display: block;
						margin: 0;
						padding: 2.5px 5px 2.5px 5px;

					}
				}

				&.current {

					background-color: $blue-strong-focus;

					a:after {
						border: none;
					}

					i {
						color: #fff;
					}
				}

				.sidebar-text {
					position: relative;
					display: block;
					font-size: 14px/$body-font-size+em;
					padding: 5px;
				}
			}

			ul {
				&.submenu {
					li {

						&:first-child {
							.sidebar-text {
								margin-top: 10px;
							}
						}

						&:last-child {
							.sidebar-text {
								margin-bottom: 10px;
							}
						}

						.sidebar-text {
							position: inherit;
							display: inline-block;
							height: 22px;
							padding: 5px;
							margin-left: 10px;
							background-color: transparent;
						}

						a {
							text-align: left;

							&:hover span.sidebar-text {
								border-left: 2px solid $blue-primary;
								background-color: transparent;
							}
						}
					}
				}
			}
		}
	}
}