/**
 *  Font faces to use with kendo PDF generator.
 *
**/

@font-face {
	font-family: "Open sans";
	src: url("/fonts/OpenSans-Regular.ttf") format('truetype');
}

@font-face {
	font-family: "Open Sans";
	src: url("/fonts/OpenSans-Italic.ttf") format('truetype');
	font-style: italic;
}

@font-face {
	font-family: "Open Sans";
	src: url("/fonts/OpenSans-Light.ttf") format('truetype');
	font-weight: 300;
}

@font-face {
	font-family: "Open Sans";
	src: url("/fonts/OpenSans-Semibold.ttf") format('truetype');
	font-weight: 600;
}

@font-face {
	font-family: "Open Sans";
	src: url("/fonts/OpenSans-Bold.ttf") format('truetype');
	font-weight: 700;
}

@font-face {
	font-family: "Open Sans";
	src: url("/fonts/OpenSans-ExtraBold.ttf") format('truetype');
	font-weight: 900;
}

@font-face {
	font-family: "Open Sans";
	src: url("/fonts/OpenSans-LightItalic.ttf") format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "Open Sans";
	src: url("/fonts/OpenSans-SemiBoldItalic.ttf") format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: "Open Sans";
	src: url("/fonts/OpenSans-BoldItalic.ttf") format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: "Open Sans";
	src: url("/fonts/OpenSans-ExtraBoldItalic.ttf") format('truetype');
	font-weight: 900;
	font-style: italic;
}