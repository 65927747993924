/**
 * @company: Logoscorp <logoscorp.com>
 * @author: Logoscorp Dev Team <desarrollo@logoscorp.com>
 * @date: 30/05/16
 */

@import 'variables';
@import 'mixins';
@import '_font_face';
@import '_icons';
@import '_sidebar';
@import '_general_base';
@import '_patients_base';
@import '_reception';
@import '_technician';
@import '_radiologist';
@import '_transcriber';
@import '_results';
@import '_home_base';
@import '_header_base';
@import '_nav_base';
@import '_dashboard_base';
@import '_cms_base';
@import '_login_base';
@import '_forms_base';
@import '_fancy_base';
@import '_responsive-logoscorp';
@import '_modal_scanfile.scss';
