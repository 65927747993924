/*
** Banner
*/

#main-content {
    div.message_board {
        padding-right: 0;
        padding-left: 0;
    }
}

/*
** Charts
*/

svg {
    left: -7px;
    width: auto;
}

#donut-chart1, #donut-chart2, #donut-chart3 {
    height: 200px;
    width: 100%;
    position: absolute;
    right: 8%;
    margin-top: 20%;
}

.c-gray {
    font-size: 0.8em;
}

body#app-layout {
    .panel-body {
        div.message_board {
            p {
                line-height: $line-height-base;
                text-align: justify;
                font-weight: bold; 
            }
        }
    }
}

div.message_board {
    border-bottom: 1px solid $blue-secondary;
    margin-top: 50px;

    p {
        height: 50px;
        overflow-y: auto;
        vertical-align: middle;
        padding-right: 10px;
        
    }
}

#home {
    .panel-carrousel {
        margin-bottom: 4%;
        width: 100%;
    }
}


#message_panel {
    .panel-body.bg-white {
        padding: 0;
        padding-bottom: 30px;
        height: 200px;
    }

    .carousel-indicators {
        bottom: 10px;

        li {
            border: 1px solid $blue-dark;
        }

        .active{
            background-color: $blue-primary;
        }
    }
        
}


div.ui-shadow {
    display: none;
}

//Carousel
#myCarousel {
    div.carousel-inner {
        img {
            width: 100%;
            border-radius: 4px;
        }
    }
}

.carousel-control {
    &.right {
        background: none;
    }
    &.left {
        background: none;
    }
}

.mail-image {
    margin-top: 50px;
}

.alert-content{
    width: 80px;
    height: 80px;
    display: inline-block;
}

.normal-alert-message{
    background: $white url(/images/alerta_general.png) no-repeat;
}

.important-alert-message{
    background: $white url(/images/alerta_importante.png) no-repeat;
}

.full-alert-message{
    background: $white url(/images/alerta_pendiente.png) no-repeat;
}

.charts {
    .panel-heading {
        padding: 30px 15px 30px 30px;
    }

    .panel-body {
        .legend {
            padding-top: 20px;
            padding-bottom: 10px;
            margin-bottom: 20px;
            max-width: 220px;
            border-bottom: solid 1px $blue-secondary;
        }
        h4 {
            font-size: 16px/$body-font-size+em;
            font-weight: 300;
            span {
                float: right;
                font-weight: bold;
            }
        }

        @for $i from 1 through 10 {
            .leyenda#{$i} {
                border-left-width: 7px;
            }
        }

    }
}
#birth_date-error{
    white-space: initial !important;
}