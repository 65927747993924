@mixin border-radius($px) {
    -webkit-border-radius: $px;
    -moz-border-radius: $px;
    border-radius: $px;
}

#cboxLoadedContent {
    width: 100% !important;
    height: 470px;
    //padding-right: 25px;
    //overflow: hidden;
    &:hover {
        overflow-y: scroll !important; //I know!! It's wrong but doesn't have other way to add this property because one script add this sentence to the element in-line
    }

    .dropdown-menu {

    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: #fff url("/images/arrowdown.png") no-repeat 98% 50%;
        color: $blue-primary;
    }
}

#cboxOverlay {
    background: #000000;
}

#cboxTopLeft, #cboxTopRight, #cboxBottomLeft, #cboxBottomRight, #cboxMiddleLeft, #cboxMiddleRight, #cboxTopCenter, #cboxBottomCenter, #cboxLoadingGraphic {
    display: none;
}

#cboxContent {
    @include border-radius(4px);
    overflow: hidden;
    width: 100% !important;


    //padding: 15px;
    ::-webkit-scrollbar {
        width: 5px;
        //display: none;
    }

    ::-webkit-scrollbar-thumb
    {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: $grey-table;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #FFFFFF;
        border-radius: 10px;
    }

    .btn.btn-form {
        float: right;
    }

    .container-fluid {

        .modal-header {
            border-color: rgba(0,121,194,0.5);
        }

        .modal-footer {
            border-color: rgba(0,121,194,0.8);
        }

        .row:nth-child(2), .row:last-child {
            position: fixed;
            z-index: 999;
            background-color: #fff;
            @include border-radius(4px);
        }
        .row:nth-child(2) {
            top: 0;
            width: 600px;
            height: 62px;
        }

        .row:nth-child(3) {
            margin-top: 77px;
        }

        .row:last-child {
            height: 87px;
            bottom: 0;
            width: 100%;
        }

        .row:nth-last-child(2) {
            margin-bottom: 40px;
        }
    }
}

#cboxClose {
    top: 15px;
    right: 10px;
    text-indent: inherit;
    background: none;
    color: $blue-primary;
    font-size: 18px/$body-font-size+em;
    font-weight: bold;
    z-index: 90000;

    &.light {
        color: $white;
    }

    &:hover {
        color: $blue-strong-focus;
    }
}

.fancy-shadow {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
}
