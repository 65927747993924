@font-face {
	font-family: dashboard_icons;
	src: url(/fonts/mediris_icon2.woff);
	src: url(/fonts/mediris_icon2.ttf);
	font-weight: normal;
	font-style: normal;
}

.dashboard-icon {
	display: inline-block;
	font: normal normal normal 14px/1 dashboard_icons;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
}

.icon-calendar:before {
	content: "\e900";
}

.icon-engine:before {
	content: "\e901";
}

.icon-deshabilitar:before {
	content: "\e902";
}

.icon-edit:before {
	content: "\e903";
}

.icon-file:before {
	content: "\e904";
}

.icon-lock:before {
	content: "\e905";
}

.icon-print:before {
	content: "\e906";
}

.icon-time:before {
	content: "\e907";
}

.icon-urgente:before {
	content: "\e908";
}

.icon-user:before {
	content: "\e909";
}

.icon-ver:before {
	content: "\e90a";
}