#patient-search-modal {
	.modal-content, .modal-dialog {
		width: 1000px;

		table {
			colgroup {
				col:nth-child(n+4) {
					width: 17%;   
				}
			}
		}
	}

	.customer-photo {
		display: inline-block;
		width: 45px;
		height: 45px;
		border-radius: 50%;
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		vertical-align: middle;
		line-height: 32px;
		box-shadow: inset 0 0 1px #999, inset 0 0 10px rgba(0, 0, 0, .2);
		-webkit-box-shadow: inset 0 0 1px #999, inset 0 0 10px rgba(0, 0, 0, .2);
		-moz-box-shadow: inset 0 0 1px #999, inset 0 0 10px rgba(0, 0, 0, .2);
		-o-box-shadow: inset 0 0 1px #999, inset 0 0 10px rgba(0, 0, 0, .2);
		@include transition($transition08s);
	}

	.customer-name {
		display: inline-block;
		vertical-align: middle;
		line-height: 32px;
		padding-left: 3px;
	}
}

#appointment-search-modal {
	.modal-content, .modal-dialog {
		width: 900px;

	}
}

.meditron {
	.reception, .appointments {
		&.index {
			#grid {

				.k-grid-header, .k-grid-content {
					colgroup col {

						width: 9.09%;

						&:first-child {
							width: 6%;
						}

						&:nth-child(2) { //ID paciente
							width: 10%;
						}

						&:nth-child(5) {
							width: 12%;
						}

						&:nth-child(7) {
							width: 8%;
						}

						&:nth-child(8) { //Date, Start
							width: 9%;
						}

						&:nth-child(9) { //End
							width: 8%;
						}

						&:nth-child(10) { //Status
							width: 8%;
						}

						&:last-child {
							width: 7%;
						}
					}

					table {
						td, th {
							&:first-child {
								padding-left: .6em;
							}
						}
					}
				}
			}
		}
	}
}